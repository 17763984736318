// src/models/rewardModel.js
const db = require('../config/db');


// Get rewards summary for a given username
exports.getMyRewardsSummary = (username) => {
    const query = `
        SELECT COUNT(username) AS rewards, SUM(reward_points) AS earnings
        FROM rewarded_details
        WHERE username = ?`;

    return new Promise((resolve, reject) => {
        db.query(query, [username], (error, results) => {
            if (error) {
                reject(error);
            } else {
                resolve(results[0]);
            }
        });
    });
};

// Get rewards list for a given username, grouped by reward date
exports.getMyRewardsList = (username) => {
    const query = `
        SELECT FROM_UNIXTIME(reward_date, '%d-%m-%Y') AS reward_date, 
               COUNT(reward_date) AS reward_count, 
               SUM(reward_points) AS reward_points 
        FROM rewarded_details
        WHERE username = ?
        GROUP BY FROM_UNIXTIME(reward_date, '%d-%m-%Y') 
        ORDER BY reward_date DESC`;

    return new Promise((resolve, reject) => {
        db.query(query, [username], (error, results) => {
            if (error) {
                reject(error);
            } else {
                resolve(results);
            }
        });
    });
};

// Get top rewards based on the total reward points
exports.getTopRewards = () => {
    const query = `
        SELECT SUM(t1.reward_points) AS reward_points, t2.fname, t2.lname 
        FROM rewarded_details t1
        LEFT JOIN user_details t2 ON t1.username = t2.username
        GROUP BY t1.username 
        ORDER BY reward_points DESC LIMIT 10`;

    return new Promise((resolve, reject) => {
        db.query(query, (error, results) => {
            if (error) {
                reject(error);
            } else {
                resolve(results);
            }
        });
    });
};

exports.getRewardCount = (username) => {
    return new Promise((resolve, reject) => {
        const query = `
            SELECT COUNT(id) AS count 
            FROM rewarded_details 
            WHERE username = ? AND FROM_UNIXTIME(reward_date, '%Y-%m-%d') = CURDATE() 
            ORDER BY id DESC LIMIT 1`;

        db.query(query, [username], (error, results) => {
            if (error) {
                return reject(error);
            }
            resolve(results[0].count); // Return the count from the query result
        });
    });
};

exports.getUserBalance = (username) => {
    return new Promise((resolve, reject) => {
        const query = `
            SELECT cur_balance, bonus_balance 
            FROM user_details 
            WHERE username = ?`;

        db.query(query, [username], (error, results) => {
            if (error) {
                return reject(error);
            }
            resolve(results[0]); // Return user balance details
        });
    });
};

exports.addRewardDetails = (username, reward_points, reward_date) => {
    return new Promise((resolve, reject) => {
        const query = `
            INSERT INTO rewarded_details (username, reward_points, reward_date) 
            VALUES (?, ?, ?)`;

        db.query(query, [username, reward_points, reward_date], (error, results) => {
            if (error) {
                return reject(error);
            }
            resolve(results); // Return the result of the insert
        });
    });
};

exports.updateUserBalance = async (username, newTotCoins, newBonusCoins) => {
    const query = `
        UPDATE user_details 
        SET cur_balance = ?, bonus_balance = ? 
        WHERE username = ?`;

    try {
        const [results] = await db.query(query, [newTotCoins, newBonusCoins, username]);
        return results;
    } catch (error) {
        throw error;
    }
};

 // Function to fetch redeem coins details
 exports.getRedeemCoins = async () => {
    const query = `
        SELECT id, title, subtitle, message, amount, coins, image, status, type, currency 
        FROM payout_details 
        WHERE type = 0 AND status = 0 
        ORDER BY id DESC
    `;
    try {
        const results = await db.query(query); // Await the query execution
        return results[0]; // Return the results directly
    } catch (err) {
        throw new Error(err); // Throw the error to be handled by the calling function
    }
};

  // Function to fetch add coins details
  exports.getAddCoins = async () => {
    const query = `
        SELECT id, title, subtitle, message, amount, coins, image, status, type, currency 
        FROM payout_details 
        WHERE type = 1 AND status = 0 
        ORDER BY id DESC
    `;

    try {
        const results = await db.query(query); // Await query execution
        return results[0];
    } catch (err) {
        throw new Error(err); // Throw error for controller to handle
    }
};

