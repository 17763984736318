// constants.js
const JWT_SECRET = process.env.JWT_SECRET || 'm!sk*7hsdy$3k';

module.exports = {
    refer_bonus: 5, 
    referer_bonus:10,
    use_of_bonus:5,
    access_key: "YOUR_ACCESS_KEY_HERE",
    JWT_SECRET: JWT_SECRET,  
    FIREBASE_SERVER_KEY: "your-server-key-here",
    
    server: {
        port: 3000
    },

    // Database connection parameters
    database: {
        host: "localhost",
        user: "root",
        password: "your_database_password",
        database: "your_database_name"
    }
};
