// src/controllers/transactionController.js
const transactionModel = require('../models/transactionModel');
const moment = require('moment');
const axios = require('axios');



// Controller to get the user's transaction details
exports.getMyTransactions = async (req, res) => {
    const { user_id } = req.body;

    try {
        const transactions = await transactionModel.getMyTransactions(user_id);
        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        res.json({ result: transactions });
    } catch (error) {
        console.error("Error fetching user transactions:", error);
        res.status(500).json({ error: "An error occurred while fetching the user transactions." });
    }
};

// Controller to get user's offline transactions
exports.getMyoffTransactions = async (req, res) => {
    const { user_id } = req.body;

    try {
        const transactions = await transactionModel.getMyoffTransactions(user_id);
        res.json({ result: transactions });
    } catch (error) {
        res.status(500).json({ error: "An error occurred while fetching offline transactions." });
    }
};

exports.AddMransaction = async (req, res) => {
    const { user_id, transaction_id, amount, method, note, coin } = req.body;

    if (!user_id || !transaction_id || !amount || !method || !note || !coin) {
        return res.status(400).json({ msg: 'Missing required parameters.' });
    }

    try {
        // Check if there's already a pending transaction for the user
        const existingTransactionCount = await transactionModel.getPendingMransactionCount(user_id);

        if (existingTransactionCount == 0) {
            // Insert new transaction data
            const data = {
                user_id,
                transaction_id,
                amount,
                wallet: method,
                note,
                coins: coin,
                created_at: Math.floor(Date.now() / 1000), // current timestamp
                status: '0', // Status 0 means pending
            };

            await transactionModel.addMransaction(data);

            return res.json({ result: [{ msg: "Your request has been successfully sent. Please wait for approval.", success: '1' }] });
        } else {
            return res.json({ result: [{ msg: "Oops! You can't send a new add play coins request until the previous one is reviewed.", success: '0' }] });
        }
    } catch (error) {
        return res.status(500).json({ msg: 'Internal Server Error' });
    }
};

// Function to handle adding a transaction
exports.addTransaction = async (req, res) => {
    const { request_name, req_from, coins_used, user_id, req_amount, getway_name, remark, type, order_id, payment_id } = req.body;
  
    if (request_name && req_from) {
      // Case 1: Adding a new transaction request
      const play_coins = parseInt(coins_used, 10);
      const current_time = moment().unix();
      const new_order_id = `${current_time}${user_id}`;
  
      try {
        const canProceed = await transactionModel.checkPendingRequest(user_id);
  
        if (!canProceed) {
          return res.status(200).json({
            result: [{ msg: "Oops! You can't send new redeem play coins request until the old one is reviewed.", success: '0' }],
          });
        }
  
        // Get current user balance
        const userDetails = await transactionModel.getUserDetails(user_id);
        const { cur_balance, won_balance } = userDetails;
  
        const new_tot_coins = cur_balance - play_coins;
        const new_won_coins = won_balance - play_coins;
  
        const transactionData = {
          user_id,
          order_id: new_order_id,
          request_name,
          req_from,
          req_amount,
          coins_used: play_coins,
          getway_name,
          remark,
          type,
          date: current_time,
          status: 0,
        };
  
        const userBalanceUpdate = {
          cur_balance: new_tot_coins,
          won_balance: new_won_coins,
        };
  
        await transactionModel.insertTransaction(transactionData);
        await transactionModel.updateUserBalance(user_id, userBalanceUpdate);
  
        res.json({
          result: [{ msg: "Your request has been successfully sent. Please wait for approval.", success: '1' }],
        });
  
      } catch (err) {
        return res.status(500).json({ msg: 'Error processing request', success: '0' });
      }
    } else if (order_id && payment_id) {
      // Case 2: Approving a transaction request
      const play_coins = parseInt(coins_used, 10);
      const current_time = moment().unix();
      const new_order_id = `${current_time}${user_id}`;
  
      try {
        // Get current user balance
        const userDetails = await transactionModel.getUserDetails(user_id);
        const { cur_balance } = userDetails;
  
        const new_tot_coins = cur_balance + play_coins;
  
        const transactionData = {
          user_id,
          order_id: new_order_id,
          payment_id,
          req_amount,
          coins_used: play_coins,
          getway_name,
          remark,
          type,
          date: current_time,
          status: 1,
        };
  
        const userBalanceUpdate = { cur_balance: new_tot_coins };
  
        await transactionModel.insertTransaction(transactionData);
        await transactionModel.updateUserBalance(user_id, userBalanceUpdate);
  
        res.json({
          result: [{ msg: "Your request has been successfully approved. Please check your wallet.", success: '1' }],
        });
  
      } catch (err) {
        return res.status(500).json({ msg: 'Error processing approval', success: '0' });
      }
    } else {
      res.status(400).json({ msg: 'Invalid request', success: '0' });
    }
  };
  
  exports.checkTransactionStatus = async (req, res) => {
    const clientTxnId = req.body.client_txn_id; 
    const key = "f27d69a0-00af-4602-bf7b-0d65778097d8";

    if (!clientTxnId) {
        return res.status(400).json({ error: 'Client transaction ID is required' });
    }

    const postData = {
        key: key,
        client_txn_id: clientTxnId,
        txn_date: moment().format('DD-MM-YYYY')
    };

    try {
        const response = await axios.post('https://api.ekqr.in/api/check_order_status', postData, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        const result = response.data;

        if (response.data.status == true) {
            const playCoins = result.data.udf2;
            const userId = result.data.udf1;
            const orderId = result.data.id;
            const paymentId = result.data.upi_txn_id;
            const amount = result.data.amount;

            // // Handle database operations
            await transactionModel.updateUserTransactionBalance(userId, playCoins, orderId, paymentId, amount);
                
            return res.json({ message: 'Transaction processed successfully', status: 'success' });
        }
        return res.status(400).json({ error: 'Transaction not successful' });

    } catch (error) {
        return res.status(500).json({ error: 'Internal server error' });
    }
};