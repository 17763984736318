
const express = require('express');
const router = express.Router();
const rewardController = require('../controllers/rewardController');
const auth = require('../middleware/auth');

router.get('/get_my_rewards_summary', rewardController.getMyRewardsSummary);
router.get('/get_my_rewardsList', rewardController.getMyRewardsList);
router.get('/get_top_rewards', rewardController.getTopRewards);
router.get('/add_reward', rewardController.addReward);

router.post('/get_redeem_coins',auth, rewardController.getRedeemCoins);
router.post('/get_add_coins',auth, rewardController.getAddCoins);

module.exports = router;