
const express = require('express');
const router = express.Router();
const authController = require('../controllers/authController');
const auth = require('../middleware/auth');
const key_auth = require('../middleware/key_auth');


router.post('/verify_refer',key_auth, authController.verifyRefer);
router.post('/verify_mobile',key_auth, authController.verifyMobile);
router.post('/verify_register',key_auth, authController.verifyRegister);
router.post('/verify_card',key_auth, authController.verifyCard);


router.get('/google', authController.googleAuth);
router.get('/google/callback', authController.googleAuthCallback);
router.post('/google/refreshToken', authController.refreshAccessToken);


router.post('/send_otp',key_auth, authController.sendOtp);
router.post('/verify_otp',key_auth, authController.verifyOtp);

module.exports = router;