const gameModel = require('../models/gameModel');
const jwt = require('jsonwebtoken');
const { use_of_bonus, JWT_SECRET } = require('../config/constants');
const md5 = require('md5');
const moment = require('moment');
const { v4: uuidv4 } = require('uuid');

// Controller for getting game list
exports.retrieveGameList = async (req, res) => {
    try {
        const games = await gameModel.getGameList();

        // Set Content-Type to JSON and return the game list
        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        res.json(games);
    } catch (error) {
        console.error("Error fetching game list:", error);
        res.status(500).json({ error: "An error occurred while fetching the game list." });
    }
};

// Controller for fetching game listings (slightly modified name)
exports.retrieveBannerList = async (req, res) => {
    try {
        const games = await gameModel.fetchBannerListings();

        // Set Content-Type to JSON and return the game list
        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        res.json(games);
    } catch (error) {
        console.error("Error fetching game listings:", error);
        res.status(500).json({ error: "An error occurred while fetching the game listings." });
    }
};

// Controller to fetch match play details
exports.getMatchPlay = async (req, res) => {
    const userId = req.body.user_id;
    const gameId = req.body.game_id;

    try {
        const matchPlayData = await gameModel.getMatchPlayDetails(userId, gameId);

        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        res.json({ result: matchPlayData });
    } catch (error) {
        console.error("Error fetching match play data:", error);
        res.status(500).json({ error: "An error occurred while fetching match play data." });
    }
};

// Controller to fetch live match details
exports.getMatchLive = async (req, res) => {
    const userId = req.body.user_id;
    const gameId = req.body.game_id;

    try {
        const matchLiveData = await gameModel.getMatchLiveDetails(userId, gameId);

        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        res.json({ result: matchLiveData });
    } catch (error) {
        console.error("Error fetching live match data:", error);
        res.status(500).json({ error: "An error occurred while fetching live match data." });
    }
};

// Controller to fetch match results
exports.getMatchResult = async (req, res) => {
    const userId = req.body.user_id;
    const gameId = req.body.game_id;

    try {
        const matchResultData = await gameModel.getMatchResultDetails(userId, gameId);

        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        res.json({ result: matchResultData });
    } catch (error) {
        console.error("Error fetching match result data:", error);
        res.status(500).json({ error: "An error occurred while fetching match result data." });
    }
};

// Controller to fetch upcoming matches
exports.getMatchUpcoming = async (req, res) => {
    const userId = req.body.user_id;

    try {
        const matchUpcomingData = await gameModel.getMatchUpcomingDetails(userId);

        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        res.json({ result: matchUpcomingData });
    } catch (error) {
        console.error("Error fetching match upcoming data:", error);
        res.status(500).json({ error: "An error occurred while fetching match upcoming data." });
    }
};

// Controller to fetch ongoing matches
exports.getMatchOngoing = async (req, res) => {
    const userId = req.body.user_id;

    try {
        const matchOngoingData = await gameModel.getMatchOngoingDetails(userId);
        res.json({ result: matchOngoingData });
    } catch (error) {
        console.error("Error fetching ongoing matches data:", error);
        res.status(500).json({ error: "An error occurred while fetching ongoing matches data." });
    }
};

// Controller to fetch completed matches
exports.getMatchCompleted = async (req, res) => {
    const userId = req.body.user_id;
    try {
        const matchCompletedData = await gameModel.getMatchCompletedDetails(userId);

        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        res.json({ result: matchCompletedData });
    } catch (error) {
        console.error("Error fetching completed matches data:", error);
        res.status(500).json({ error: "An error occurred while fetching completed matches data." });
    }
};

// Controller to fetch match timer
exports.getMatchTimer = async (req, res) => {
    const matchId = req.body.match_id;

    try {
        const matchTimerData = await gameModel.getMatchTimerDetails(matchId);

        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        res.json({ result: [matchTimerData] });
    } catch (error) {
        console.error("Error fetching match timer data:", error);
        res.status(500).json({ error: "An error occurred while fetching match timer data." });
    }
};

// Controller to fetch room details
exports.getRoomDetails = async (req, res) => {
    const matchId = req.body.match_id;

    try {
        const roomDetailsData = await gameModel.getRoomDetails(matchId);

        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        res.json({ result: [roomDetailsData] });
    } catch (error) {
        console.error("Error fetching room details data:", error);
        res.status(500).json({ error: "An error occurred while fetching room details data." });
    }
};


// Controller to get participants of a match
exports.getMatchParticipants = async (req, res) => {
   const { match_id } = req.body;

   try {
       const participants = await gameModel.getMatchParticipants(match_id);
       res.setHeader('Content-Type', 'application/json; charset=utf-8');
       res.json({ result: participants });
   } catch (error) {
       console.error("Error fetching match participants:", error);
       res.status(500).json({ error: "An error occurred while fetching match participants." });
   }
};

// Controller to get a user's entries in a match
exports.getMyEntries = async (req, res) => {
   const { match_id, user_id } = req.body;

   try {
       const entries = await gameModel.getMyEntries(match_id, user_id);
       res.setHeader('Content-Type', 'application/json; charset=utf-8');
       res.json({ result: entries });
   } catch (error) {
       console.error("Error fetching user entries:", error);
       res.status(500).json({ error: "An error occurred while fetching user entries." });
   }
};


// Controller to update a user's game username (PUBG ID) for a match
exports.updateMyEntries = async (req, res) => {
   const { id, match_id, user_id, pubg_id } = req.body;

   try {
       const result = await gameModel.updateMyEntries(id, match_id, user_id, pubg_id);

       res.setHeader('Content-Type', 'application/json; charset=utf-8');
       res.json(result);
   } catch (error) {
       console.error("Error updating match entry:", error);
       res.status(500).json({ error: "An error occurred while updating your match entry." });
   }
};

// Controller to get the match winner (Position 1)
exports.getMatchWinner = async (req, res) => {
   const { match_id } = req.body;

   try {
       const winner = await gameModel.getMatchWinner(match_id);
       res.setHeader('Content-Type', 'application/json; charset=utf-8');
       res.json({ result: winner });
   } catch (error) {
       console.error("Error fetching match winner:", error);
       res.status(500).json({ error: "An error occurred while fetching the match winner." });
   }
};


// Controller to get match runner-ups (Position 2-10)
exports.getMatchRunnerup = async (req, res) => {
   const { match_id } = req.body;

   try {
       const runnerups = await gameModel.getMatchRunnerup(match_id);
       res.setHeader('Content-Type', 'application/json; charset=utf-8');
       res.json({ result: runnerups });
   } catch (error) {
       console.error("Error fetching match runner-ups:", error);
       res.status(500).json({ error: "An error occurred while fetching match runner-ups." });
   }
};

// Controller to get the full result of the match (All participants)
exports.getMatchFullResult = async (req, res) => {
   const { match_id } = req.body;

   try {
       const fullResult = await gameModel.getMatchFullResult(match_id);
       res.setHeader('Content-Type', 'application/json; charset=utf-8');
       res.json({ result: fullResult });
   } catch (error) {
       console.error("Error fetching full match result:", error);
       res.status(500).json({ error: "An error occurred while fetching the full match result." });
   }
};

// Controller function
exports.joinMatch = async (req, res) => {
    try {
        const {
            match_id,
            username,
            user_id,
            name,
            slots // Array of slots selected by the player
        } = req.body;

        if (!match_id || !slots || !Array.isArray(slots) || slots.length === 0) {
            return res.status(400).json({ result: [{ msg: "Missing required parameters or invalid slots", success: '0' }] });
        }

        const matchDetails = await gameModel.getMatchDetails(match_id);
        const entryFee = matchDetails.entry_fee;
        const entryType = matchDetails.entry_type;
        const matchType = matchDetails.match_type;

        const bonus = Math.round((use_of_bonus * entryFee) / 100);

         // Validate slots based on match type
         if (matchType === "Solo" && slots.length !== 1) {
            return res.status(400).json({ result: [{ msg: "For a Solo match, only one slot is allowed.", success: '0' }] });
        }
        if (matchType === "Duo" && slots.length > 2) {
            return res.status(400).json({ result: [{ msg: "For a Duo match, you can select up to 2 slots.", success: '0' }] });
        }
        if (matchType === "Squad" && slots.length > 4) {
            return res.status(400).json({ result: [{ msg: "For a Squad match, you can select up to 4 slots.", success: '0' }] });
        }

        // Check if the slots array is empty (no slots selected)
        if (slots.length === 0) {
            return res.status(400).json({ result: [{ msg: "No slots selected. Please choose at least one slot.", success: '0' }] });
        }

        // Check for duplicate pubg_id in the slots array
        const pubgIds = slots.map(slot => slot.pubgId);
        const uniquePubgIds = new Set(pubgIds);

        if (pubgIds.length !== uniquePubgIds.size) {
            return res.status(400).json({ result: [{ msg: "Duplicate PUBG usernames found in selected slots. Each slot must have a unique PUBG username.", success: '0' }] });
        }

        // Check if the PUBG username already exists
        const existingParticipants = await gameModel.getParticipants(match_id, slots);
        if (existingParticipants.length > 0) {
            const existingPubgIds = existingParticipants.map(participant => participant.pubgId);
            return res.json({ 
                result: [{ 
                    msg: `The following PUBG usernames already exist.`, 
                    success: '0' 
                }] 
            });
        }



        // Check if any of the slots are already taken
        const takenSlots = await gameModel.checkSlotsTaken(match_id, slots);
        if (takenSlots.length > 0) {
            return res.status(400).json({ 
                result: [{ 
                    msg: `The following slot ${takenSlots} is already taken.`, 
                    success: '0' 
                }] 
            });
        }
        if (entryType === 'Paid') {
            const referral = await gameModel.getReferral(username);

            let joinResult;
            // Check if referral exists and has a refer_code
            if (referral.length > 0) {
                // If referral is found, proceed with referral-based join
                joinResult = await gameModel.joinPaidMatch(match_id, user_id, name, slots, entryFee, bonus, referral[0].refer_code);
            } else {
                // If no referral is found (either empty array or no refer_code), proceed without referral
                joinResult = await gameModel.joinPaidMatch(match_id, user_id, name, slots, entryFee, bonus);
            }

            if (joinResult.success) {
                return res.json({ result: [{ msg: "Joined successfully!", success: '2' }] });
            } else {
                return res.json({ result: [{ msg: joinResult.message, success: '0' }] });
            }
        } else {
            const joinResult = await gameModel.joinFreeMatch(match_id, user_id, name, slots);
            
            if (joinResult.success) {
                return res.json({ result: [{ msg: "Joined successfully!", success: '2' }] });
            } else {
                return res.json({ result: [{ msg: joinResult.message, success: '0' }] });
            }
        }
    } catch (error) {
        console.error(error);
        res.status(500).json({ result: [{ msg: "Internal server error", success: '0' }] });
    }
};


exports.getSlots = async (req, res) => {
    try {
        const { match_id } = req.body;

        // Validate match_id
        if (!match_id) {
            return res.status(400).json({
                status: false,
                title: 'Error!',
                message: "Match ID is required",
            });
        }

        // Fetch match details
        const match = await gameModel.getSlotsMatchDetails(match_id);

        if (!match) {
            return res.status(404).json({
                status: false,
                title: 'Error!',
                message: "Match not found",
            });
        }

        // Fetch participant details
        const participants = await gameModel.getSlotsParticipantDetails(match_id);

        // Prepare slots data
        const numberOfPositions = match.room_size;
        const matchType = match.match_type;
        const data = [];
        const teams = {};

        // Initialize teams and positions based on match type
        const positionsPerTeam = matchType === 'Solo' ? 1 : matchType === 'Duo' ? 2 : 4;
        const totalTeams = matchType === 'Solo' ? 1 : Math.ceil(numberOfPositions / positionsPerTeam); // Only 1 team in Solo

        if (matchType === 'Solo') {
            // For Solo, only one team and increment positions
            teams[1] = Array.from({ length: numberOfPositions }, (_, pos) => ({
                user_name: '',
                pubg_id: '',
                team: 1,
                position: pos + 1,
            }));
        } else {
            // For Duo and Squad, increment teams and positions per team
            for (let team = 1; team <= totalTeams; team++) {
                teams[team] = Array.from({ length: positionsPerTeam }, (_, pos) => ({
                    user_name: '',
                    pubg_id: '',
                    team,
                    position: pos + 1,
                }));
            }
        }

        // Populate participant data into teams
        participants[0].forEach(participant => {
            const { username, pubg_id, team, position } = participant;
            if (teams[team] && teams[team][position - 1]) {
                teams[team][position - 1] = {
                    user_name: username,
                    pubg_id,
                    team,
                    position,
                };
            }
        });

        // Flatten teams into the result array
        for (const team in teams) {
            data.push(...teams[team]);
        }

        // Prepare response
        const response = {
            status: true,
            title: 'Success!',
            message: {
                match,
                result: data,
            },
        };

        return res.json(response);
    } catch (error) {
        console.error("Error fetching slots:", error.message);
        return res.status(500).json({
            status: false,
            title: 'Error!',
            message: "Internal server error",
        });
    }
};
