const miscModel = require('../models/miscModel');
const md5 = require('md5');
const moment = require('moment');
const { v4: uuidv4 } = require('uuid');


exports.getContactUs = async (req, res) => {
    try {
        const contactInfo = await miscModel.getContactDetails();

        if (contactInfo) {
            const result = {
                result: [{
                    title: contactInfo.title,
                    phone: contactInfo.phone,
                    email: contactInfo.email,
                    address: contactInfo.address,
                    other: contactInfo.other,
                    whatsapp_no: contactInfo.whatsapp_no,
                    messenger_id: contactInfo.messenger_id,
                    fb_follow: contactInfo.fb_follow,
                    ig_follow: contactInfo.ig_follow,
                    twitter_follow: contactInfo.twitter_follow,
                    youtube_follow: contactInfo.youtube_follow,
                    success: '1'
                }]
            };
            res.status(200).json(result);
        } else {
            res.status(404).json({ success: '0', message: 'Contact details not found' });
        }
    } catch (error) {
        console.error(error);
        res.status(500).json({ success: '0', message: 'Internal Server Error' });
    }
};

exports.getPrivacyPolicy = async (req, res) => {
   try {
       const policyContent = await miscModel.getPolicyContent();

       if (policyContent) {
           const result = {
               result: [{
                   content: policyContent.content,
                   success: '1'
               }]
           };
           res.status(200).json(result);
       } else {
           res.status(404).json({ success: '0', message: 'Privacy policy not found' });
       }
   } catch (error) {
       console.error(error);
       res.status(500).json({ success: '0', message: 'Internal Server Error' });
   }
};

exports.getTermsConditions = async (req, res) => {
   try {
       const termsContent = await miscModel.getTermsContent();

       if (termsContent) {
           const result = {
               result: [{
                   content: termsContent.content,
                   success: '1'
               }]
           };
           res.status(200).json(result);
       } else {
           res.status(404).json({ success: '0', message: 'Terms and Conditions not found' });
       }
   } catch (error) {
       console.error(error);
       res.status(500).json({ success: '0', message: 'Internal Server Error' });
   }
};


exports.getAboutUs = async (req, res) => {
   try {
       const aboutContent = await miscModel.getAboutContent();

       if (aboutContent) {
           const result = {
               result: [{
                   content: aboutContent.content,
                   success: '1'
               }]
           };
           res.status(200).json(result);
       } else {
           res.status(404).json({ success: '0', message: 'About Us content not found' });
       }
   } catch (error) {
       console.error(error);
       res.status(500).json({ success: '0', message: 'Internal Server Error' });
   }
};

exports.getFAQ = async (req, res) => {
   try {
       const faqContent = await miscModel.getFAQContent();

       if (faqContent) {
           const result = {
               result: [{
                   content: faqContent.content,
                   success: '1'
               }]
           };
           res.status(200).json(result);
       } else {
           res.status(404).json({ success: '0', message: 'FAQ content not found' });
       }
   } catch (error) {
       console.error(error);
       res.status(500).json({ success: '0', message: 'Internal Server Error' });
   }
};

exports.getAnnouncement = async (req, res) => {
    try {
        const announcements = await miscModel.getLatestAnnouncements();

        if (announcements && announcements.length > 0) {
            return res.status(200).json({ Result: announcements });
        } else {
            return res.status(404).json({ Result: [], message: "No announcements found" });
        }
    } catch (error) {
        console.error("Error in getAnnouncement Controller:", error.message);
        return res.status(500).json({ Result: [], message: "Internal Server Error" });
    }
};


exports.getNotification = async (req, res) => {
   try {
       const notifications = await miscModel.getLatestNotifications();

       if (notifications && notifications.length > 0) {
           res.status(200).json({ "notifications": notifications });
       } else {
           res.status(404).json({ message: 'No notifications found' });
       }
   } catch (error) {
       console.error(error);
       res.status(500).json({ message: 'Internal Server Error' });
   }
};

exports.getUpdateApp = async (req, res) => {
   try {
       const updateInfo = await miscModel.getUpdateDetails();

       if (updateInfo) {
           res.status(200).json({
               result: [
                   {
                       id: updateInfo.id,
                       force_update: updateInfo.force_update,
                       whats_new: updateInfo.whats_new.replace(/<[^>]*>/g, ""), // Removing HTML tags
                       update_date: updateInfo.update_date,
                       latest_version_name: updateInfo.latest_version_name,
                       latest_version_code: updateInfo.latest_version_code,
                       update_url: updateInfo.update_url,
                       success: '1'
                   }
               ]
           });
       } else {
           res.status(404).json({ message: 'No update information found' });
       }
   } catch (error) {
       console.error(error);
       res.status(500).json({ message: 'Internal Server Error' });
   }
};

exports.getSlider = async (req, res) => {
   const { prod_id } = req.body;

   if (!prod_id) {
       return res.status(400).json({ msg: 'Missing prod_id parameter.' });
   }

   try {
       const sliderImages = await miscModel.getSliderImages(prod_id);

       if (sliderImages.length > 0) {
           res.status(200).json(sliderImages);
       } else {
           res.status(404).json({ msg: 'No images found for the given product ID.' });
       }
   } catch (error) {
       console.error(error);
       res.status(500).json({ msg: 'Internal Server Error' });
   }
};

exports.getProducts = async (req, res) => {
   try {
       const products = await miscModel.getAllProducts();

       if (products.length > 0) {
           res.status(200).json(products);
       } else {
           res.status(404).json({ msg: 'No products found.' });
       }
   } catch (error) {
       console.error(error);
       res.status(500).json({ msg: 'Internal Server Error' });
   }
};
