// src/controllers/rewardsController.js
const rewardModel = require('../models/rewardModel');

// Controller to get rewards summary for a given username
exports.getMyRewardsSummary = async (req, res) => {
    const { username } = req.body;

    try {
        const rewardsSummary = await rewardModel.getMyRewardsSummary(username);
        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        // Send output in the exact format as PHP
        res.json({
            result: [{
                rewards: rewardsSummary.rewards,
                earnings: rewardsSummary.earnings,
                success: '1'
            }]
        });
    } catch (error) {
        console.error("Error fetching rewards summary:", error);
        res.status(500).json({ error: "An error occurred while fetching the rewards summary." });
    }
};


// Controller to get rewards list for a given username
exports.getMyRewardsList = async (req, res) => {
    const { username } = req.body;

    try {
        const rewardsList = await rewardModel.getMyRewardsList(username);
        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        // Send output in the exact format as PHP
        res.json(rewardsList.map(reward => ({
            reward_date: reward.reward_date,
            reward_count: reward.reward_count,
            reward_points: reward.reward_points
        })));
    } catch (error) {
        console.error("Error fetching rewards list:", error);
        res.status(500).json({ error: "An error occurred while fetching the rewards list." });
    }
};

// Controller to get top rewards based on reward points
exports.getTopRewards = async (req, res) => {
    try {
        const topRewards = await rewardModel.getTopRewards();
        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        // Send output in the exact format as PHP
        res.json(topRewards.map(reward => ({
            reward_points: reward.reward_points,
            fname: reward.fname,
            lname: reward.lname
        })));
    } catch (error) {
        console.error("Error fetching top rewards:", error);
        res.status(500).json({ error: "An error occurred while fetching the top rewards." });
    }
};


exports.addReward = async (req, res) => {
    const { username, reward_points, reward_limits } = req.body;

    if (!username || !reward_points || !reward_limits) {
        return res.status(400).json({ msg: 'Missing username, reward_points, or reward_limits parameter.' });
    }

    try {
        const current_time = Math.floor(Date.now() / 1000);
        const rewardCount = await rewardModel.getRewardCount(username);

        let count = rewardCount + 1;

        if (count >= reward_limits) {
            const user = await rewardModel.getUserBalance(username);
            const newTotCoins = user.cur_balance + Number(reward_points);
            const newBonusCoins = user.bonus_balance + Number(reward_points);

            // Insert reward details and update user balance
            await rewardModel.addRewardDetails(username, reward_points, current_time);
            await rewardModel.updateUserBalance(username, newTotCoins, newBonusCoins);

            const diff = (current_time + 86400 - (current_time % 86400)) - current_time;

            return res.json({ result: [{ msg: diff, success: '0' }] });
        } else {
            // Insert reward details even if the reward is not granted yet
            await rewardModel.addRewardDetails(username, 0, current_time);

            const diff = reward_limits - count;
            return res.json({ result: [{ msg: `Please complete this task. ${diff} time(s) left to redeem reward.`, success: '1' }] });
        }
    } catch (error) {
        console.error(error);
        res.status(500).json({ msg: 'Internal Server Error' });
    }
};

  // Function to handle fetching redeem coins
  exports.getRedeemCoins = async (req, res) => {
    try {
        const results = await rewardModel.getRedeemCoins(); // Await the asynchronous model function
        res.json({ result: results }); // Send the results as a JSON response
    } catch (err) {
        res.status(500).json({ msg: 'Error fetching redeem coins', success: '0', error: err.message });
    }
};

  
  // Function to handle fetching add coins data
  exports.getAddCoins = async (req, res) => {
    try {
        const results = await rewardModel.getAddCoins(); // Await model response
        res.json({ result: results }); // Send successful response
    } catch (err) {
        res.status(500).json({ msg: 'Error fetching add coins', success: '0', error: err.message });
    }
};