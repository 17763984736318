const ACCESS_KEY = process.env.ACCESS_KEY || 'xntjrgfq-rmhclwvk-gpjbzqxd-syhnemcw';

const key_auth = (req, res, next) => {
    
    // Check the access key in the request body
    // const requestAccessKey = req.body.access_key; 

    const requestAccessKey = req.header('Authorization')?.split(' ')[1];
    if (!requestAccessKey || requestAccessKey !== ACCESS_KEY) {
        return res.status(400).json({ status: "6", message: 'Access denied. Invalid access key.' });
    }

    next();
};

module.exports = key_auth;
