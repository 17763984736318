const jwt = require('jsonwebtoken');
const NodeCache = require('node-cache'); // Simple in-memory caching
const JWT_SECRET = process.env.JWT_SECRET || 'm!Q3P$V%8Xy*Z@Lr7jF';
const ACCESS_KEY = process.env.ACCESS_KEY || 'xntjrgfq-rmhclwvk-gpjbzqxd-syhnemcw';

const cache = new NodeCache({ stdTTL: 300, checkperiod: 60 }); // Tokens cached for 5 minutes

const auth = async (req, res, next) => {
    try {
        // Extract Bearer token from the Authorization header
        const token = req.header('Authorization')?.split(' ')[1];
        if (!token) {
            return res.status(401).json({ status: "5", message: 'Access denied. No token provided.' });
        }

        // Validate the access key in the request body
        const requestAccessKey = req.body.access_key;
        if (requestAccessKey !== ACCESS_KEY) {
            return res.status(403).json({ status: "6", message: 'Access denied. Invalid access key.' });
        }

        // Check if token is already cached
        const cachedUser = cache.get(token);
        if (cachedUser) {
            req.user = cachedUser; // Attach cached user data to req.user
            return next();
        }

        // Verify the JWT token asynchronously
        const decoded = await jwt.verify(token, JWT_SECRET);
        req.user = decoded; // Attach decoded token data to req.user

        // Cache the decoded token
        cache.set(token, decoded);

        next();
    } catch (error) {
        // Handle token verification or other errors
        res.status(400).json({ status: "5", message: 'Invalid token' });
    }
};

module.exports = auth;
