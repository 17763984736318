// src/models/transactionModel.js

const db = require('../config/db');

// Get user's transactions
exports.getMyTransactions = async (userId) => {
    const query = `
        SELECT id, user_id, order_id, payment_id, req_amount, remark, type, 
               FROM_UNIXTIME(date, '%d-%m-%Y') AS date, getway_name, 
               coins_used, status, request_name, req_from
        FROM transaction_details
        WHERE user_id = ?
        ORDER BY id DESC
    `;

    try {
        const [results] = await db.query(query, [userId]); // Await query execution
        return results; // Return the results
    } catch (err) {
        throw new Error(err); // Throw an error for the calling function to handle
    }
};

// Get user's offline transactions
exports.getMyoffTransactions = async (userId) => {
    const query = `
        SELECT id, user_id, transaction_id, amount, wallet, note, coins, 
               FROM_UNIXTIME(created_at, '%d-%m-%Y') AS created_at, status
        FROM tbl_offline_plyments
        WHERE user_id = ?
        ORDER BY id DESC`;

    try {
        const [results] = await db.query(query, [userId]);
        return results;
    } catch (error) {
        throw error;
    }
};

exports.getPendingMransactionCount = async (user_id) => {
    try {
        const query = `
            SELECT COUNT(id) AS count 
            FROM tbl_offline_plyments 
            WHERE user_id = ? AND status = '0'`;

        const [results] = await db.query(query, [user_id]);
        return results[0].count;
    } catch (error) {
        throw error;
    }
};

exports.addMransaction = async (data) => {
    try {
        const query = `
            INSERT INTO tbl_offline_plyments (user_id, transaction_id, amount, wallet, note, coins, created_at, status) 
            VALUES (?, ?, ?, ?, ?, ?, ?, ?)`;

        const [results] = await db.query(query, [
            data.user_id,
            data.transaction_id,
            data.amount,
            data.wallet,
            data.note,
            data.coins,
            data.created_at,
            data.status,
        ]);
        return results[0];
    } catch (error) {
        throw error;
    }
};

// Function to check if there are pending requests
exports.checkPendingRequest = async (user_id) => {
    const query = 'SELECT id FROM transaction_details WHERE user_id = ? AND status = 0';
  
    try {
      const [results] = await db.query(query, [user_id]);
      return results.length === 0;
    } catch (err) {
      throw err; 
    }
  };
  
    // Function to get user balance details
    exports.getUserDetails = async (user_id) => {
        const query = 'SELECT cur_balance, won_balance FROM user_details WHERE id = ?';
    
        try {
            const [results] = await db.query(query, [user_id]);
            return results[0]; // Return the first record (assuming a single user is returned)
        } catch (error) {
            throw error;
        }
    };

      // Function to insert transaction details
exports.insertTransaction = (data, callback) => {
    const query = 'INSERT INTO transaction_details SET ?';
    db.query(query, data, (err, result) => {
      if (err) return callback(err);
      callback(null, result);
    });
  };
  
  // Function to update user balance
exports.updateUserBalance = (user_id, data, callback) => {
    const query = 'UPDATE user_details SET ? WHERE id = ?';
    db.query(query, [data, user_id], (err, result) => {
      if (err) return callback(err);
      callback(null, result);
    });
  };
  

  exports.updateUserTransactionBalance = async (userId, playCoins, orderId, paymentId, amount) => {
    const currentTime = Math.floor(Date.now() / 1000); 

    // Step 1: Get current balance of the user
    const [userData] = await db.query('SELECT cur_balance FROM user_details WHERE id = ?', [userId]);
    
    if (!userData) {
        throw new Error('User not found');
    }
    const totalCoins = parseInt(userData[0].cur_balance, 10);
    const newTotalCoins = totalCoins + parseInt(playCoins, 10); 
    

    // Step 2: Insert transaction details
    const transactionData = {
        user_id: userId,
        order_id: orderId,
        payment_id: paymentId,
        req_amount: amount,
        coins_used: playCoins,
        getway_name: 'ANY UPI PAYMENT',
        remark: 'added from payment gateway',
        type: '1', 
        status: 1,
        date: currentTime
    };

    try {
        // Insert transaction
        await db.query('INSERT INTO transaction_details SET ?', transactionData);

        // Update user balance
        await db.query('UPDATE user_details SET `cur_balance` = ? WHERE id = ?', [newTotalCoins, userId]);

    } catch (error) {
        throw new Error('Error updating transaction or user balance: ' + error.message);
    }
};
