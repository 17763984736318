
const express = require('express');
const router = express.Router();
const transactionController = require('../controllers/transactionController');
const auth = require('../middleware/auth');

router.post('/get_my_transactions',auth, transactionController.getMyTransactions);
router.post('/get_myoff_transactions',auth, transactionController.getMyoffTransactions);
router.post('/add_money',auth, transactionController.AddMransaction);
router.post('/add_transaction',auth, transactionController.addTransaction);

router.post('/redirect_url',auth, transactionController.checkTransactionStatus);


module.exports = router;