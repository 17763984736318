const lotteryModel = require('../models/lotteryModel');

// Controller to fetch lottery list
exports.getLotteryList = async (req, res) => {
    const userId = req.body.user_id;

    try {
        const lotteryListData = await lotteryModel.getLotteryList(userId);

        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        res.json({ result: lotteryListData });
    } catch (error) {
        res.status(500).json({ error: "An error occurred while fetching lottery list data." });
    }
};
// Controller to fetch lottery participants
exports.getLotteryParticipant = async (req, res) => {
   const lotteryId = req.body.lottery_id;

   try {
       const participantData = await lotteryModel.getLotteryParticipant(lotteryId);

       res.setHeader('Content-Type', 'application/json; charset=utf-8');
       res.json({ result: participantData });
   } catch (error) {
       res.status(500).json({ error: "An error occurred while fetching lottery participant data." });
   }
};

// Controller to fetch user's lottery entries
exports.getLotteryMy = async (req, res) => {
   const lotteryId = req.body.lottery_id;
   const userId = req.body.user_id;

   try {
       const myLotteryData = await lotteryModel.getLotteryMy(lotteryId, userId);

       res.setHeader('Content-Type', 'application/json; charset=utf-8');
       res.json({ result: myLotteryData });
   } catch (error) {
       console.error("Error fetching user's lottery data:", error);
       res.status(500).json({ error: "An error occurred while fetching user's lottery data." });
   }
};


// Controller to fetch lottery results
exports.getLotteryResult = async (req, res) => {
   try {
       const resultData = await lotteryModel.getLotteryResult();
       
       res.setHeader('Content-Type', 'application/json; charset=utf-8');
       res.json({ result: resultData });
   } catch (error) {
       console.error("Error fetching lottery result data:", error);
       res.status(500).json({ error: "An error occurred while fetching lottery result data." });
   }
};

// Controller to handle joining a lottery
exports.joinLottery = async (req, res) => {
    const { lottery_id, user_id, name, fee } = req.body;
    const bonus = req.body.bonus ? parseFloat(req.body.bonus) : 0;

    try {
        // Fetch lottery details, including the room size
        const lotteryDetails = await lotteryModel.getLotteryDetailsById(lottery_id);

        const roomSize = lotteryDetails.size;

        // Call the join lottery function with the room size
        const joinResult = await lotteryModel.joinLottery(lottery_id, user_id, name, parseFloat(fee), bonus, roomSize);

        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        res.json(joinResult);
    } catch (error) {
        console.error("Error joining lottery:", error);
        res.status(500).json({ error: "An error occurred while joining the lottery." });
    }
};
