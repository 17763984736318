// src/controllers/userController.js
const jwt = require('jsonwebtoken');
const userModel = require('../models/userModel');
const { refer_bonus, JWT_SECRET } = require('../config/constants');
const md5 = require('md5');
const moment = require('moment');
const { v4: uuidv4 } = require('uuid');
const axios = require('axios');

// Controller to get all users
// exports.getAllUsers = async (req, res) => {
//     try {
//         const users = await userModel.getAllUsers();
//         res.json({ success: true, users });
//     } catch (error) {
//         res.status(500).json({ success: false, error: error.message });
//     }
// };

// Controller to get a user by ID
exports.getUserProfile = async (req, res) => {
    try {
        const userId = req.body.id;

        if (!userId) {
            return res.status(400).json({ success: false, error: 'User ID is required' });
        }

        const user = await userModel.getUserProfile(userId);

        if (!user) {
            return res.status(404).json({ success: false, error: 'User not found' });
        }

        res.json({ success: "1", user });
    } catch (error) {
        res.status(500).json({ success: false, error: error.message });
    }
};

// Controller to create a new user
exports.createUser = async (req, res) => {
    try {
        const { fname, lname, username, password, email, country_code, mobile, referer, device_id } = req.body;

        // Generate UUID for id
        const id = uuidv4();

        // Check if the device is blocked
        if (await userModel.isDeviceBlocked(device_id)) {
            return res.json({ success: false, msg: "You are not eligible to create a new account. Please contact us." });
        }

        // Check if either email or referer is provided
        if (!email && !referer) {
            return res.json({ success: false, msg: "Email or referral code required!" });
        }

        // Check if the username, email, or mobile already exists
        if (await userModel.isExistingUser(username, email, mobile)) {
            return res.json({ success: false, msg: "This username, email id, or mobile number is already used" });
        }

        const userData = {
            id,  // Use UUID as the id
            user_type: 'Normal',
            fname,
            lname,
            username,
            password: md5(password),  // Hash password
            email,
            country_code,
            mobile,
            refer: username,
            created_date: new Date(),
            device_id,
            is_block: 0,
            status: 1
        };

        if (referer) {
            if (await userModel.isValidReferer(referer)) {
                // Update the referer's referral count
                await userModel.getAndUpdateTotalReferrals(referer);

                // Add referral bonus
                userData.cur_balance = refer_bonus;
                userData.won_balance = 0;
                userData.bonus_balance = refer_bonus;

                // Prepare and insert the referral details
                const referralData = {
                    username,
                    refer_points: 0,
                    refer_code: referer,
                    refer_status: 0,
                    refer_date: new Date()
                };
                await userModel.insertReferralDetails(referralData);
                userData.referer = referer;
            } else {
                return res.json({ success: false, msg: "Referral code not found or incorrect!" });
            }
        } else {
            userData.cur_balance = 0;
            userData.won_balance = 0;
            userData.bonus_balance = 0;
        }

        const newUserId = await userModel.insertUser(userData);
        res.json({ success: true, data: { id: newUserId }, msg: "Your account has been registered successfully." });
    } catch (error) {
        console.error(error);
        res.status(500).json({ success: false, error: error.message });
    }
};

// Controller to update a user by ID
exports.updateUser = async (req, res) => {
    try {
        const { id, fname, lname, gender, dob } = req.body;

        if (!fname || !lname || !gender || !dob) {
            return res.status(400).json({ success: false, error: 'Name, gender and dob are required' });
        }

        const updatedUser = await userModel.updateUser(id, { fname, lname, gender, dob });
        res.json({ success: '1', result: updatedUser, msg: "Profile Updated Successfully" });
    } catch (error) {
        res.status(500).json({ success: '0', msg: error.message });
    }
};

// Controller to delete a user by ID
exports.deleteUser = async (req, res) => {
    try {
        const userId = parseInt(req.params.id, 10);
        const result = await userModel.deleteUser(userId);
        res.json({ success: true, message: result.message });
    } catch (error) {
        res.status(500).json({ success: false, error: error.message });
    }
};

// Controller for user login
exports.userLogin = async (req, res) => {
    try {
        const { username, password, device_id, social } = req.body;

        // Check if the user is blocked based on device ID
        if (await userModel.isDeviceBlocked(device_id)) {
            return res.json({ success: false, msg: "You are not eligible to login to this account. Please contact us." });
        }

        // Standard login with password
        if (password) {
            const hashedPassword = md5(password);
            const userData = await userModel.getUserByCredentials(username, hashedPassword);
            if (userData) {
                userData.success = "1";
                // Check if account is active and not blocked
                if (userData.status == 1 && userData.is_block == 0) {
                    // Generate JWT token
                    const token = jwt.sign(
                        { id: userData.id, username: userData.username },
                        JWT_SECRET,
                        { expiresIn: '24h' } // Token expiration
                    );  

                    return res.json({
                        success: "1",
                        result: userData,
                        token, // Include the token in the response
                    });
                } else {
                    const lockMessage = userData.status === 0 ? 'Your account has been locked.' : 'Your device has been locked.';
                    return res.json({ success: "0", msg: lockMessage });
                }
            } else {
                return res.json({ success: "0", msg: 'Incorrect username or password.' });
            }
        } 
        // Social login
        else if (social === "true") {
            const userData = await userModel.getUserByUsername(username);

            if (userData) {
                // Check if account is active and not blocked
                if (userData.status == 1 && userData.is_block == 0) {
                    // Generate JWT token
                    const token = jwt.sign(
                        { id: userData.id, username: userData.username },
                        JWT_SECRET,
                        { expiresIn: '24h' } // Token expiration
                    );

                    return res.json({
                        success: "1",
                        result: userData,
                        token, // Include the token in the response
                    });
                } else {
                    const lockMessage = userData.status == 0 ? 'Your account has been locked.' : 'Your device has been locked.';
                    return res.json({ success: "0", msg: lockMessage });
                }
            } else {
                return res.json({ success: "0", msg: 'Account not found. Please sign up first.' });
            }
        } else {
            return res.status(400).json({ status: "7", message: "Invalid request. username and password is required." });
        }
    } catch (error) {
        console.error("Error during login:", error);
        return res.status(400).json({ status: "8", message: "An error occurred during login." });
    }
};






// Controller for resetting password
exports.resetPassword = async (req, res) => {
    try {
        const { password, id } = req.body;  

        if (!password || !id) {
            return res.status(400).json({
                result: [{ msg: "Password and id are required", success: "0" }]
            });
        }
        const hashedPassword = md5(password);
        // Attempt to update the password in the database
        const success = await userModel.updatePassword(id, hashedPassword);

        if (success) {
            // Password update successful
            return res.json({
                result: { msg: "Password Updated Successfully!!!", success: "1" },
                success: '1'
            });
        } else {
            // No matching record found
            return res.status(404).json({
                result: [{ msg: "User not found", success: "0" }]
            });
        }
    } catch (error) {
        return res.status(500).json({
            result: [{ msg: "An error occurred", success: "0" }]
        });
    }
};


exports.newPassword = async (req, res) => {
    try {
        const { password, email } = req.body;  

        if (!password || !email) {
            return res.status(200).json({
                message: "Password and email are required", status: "0" 
            });
        }

        const hashedPassword = md5(password);
        // Attempt to update the password in the database
        const success = await userModel.updateNewPassword(email, hashedPassword);

        if (success) {
            // Password update successful
            return res.json({
                message: "Password Updated Successfully!!!", status: "1" 
            });
        } else {
            // No matching record found
            return res.status(200).json({
                 message: "User not found", status: "0"
            });
        }
    } catch (error) {
        return res.status(400).json({
            message: "An error occurred", status: "8" 
        });
    }
};

// Controller for resetting password
exports.forgotPassword = async (req, res) => {
    try {
        const { password, email } = req.body;  

        if (!password || !email) {
            return res.status(400).json({
                result: [{ msg: "Password and email are required", success: "0" }]
            });
        }
        const hashedPassword = md5(password);
        // Attempt to update the password in the database
        const success = await userModel.resetPassword(email, hashedPassword);

        if (success) {
            // Password update successful
            return res.json({
                result: { msg: "Password Updated Successfully!!!", success: "1" },
                success: '1'
            });
        } else {
            // No matching record found
            return res.status(404).json({
                result: [{ msg: "User not found", success: "0" }]
            });
        }
    } catch (error) {
        return res.status(500).json({
            result: [{ msg: "An error occurred", success: "0" }]
        });
    }
};


exports.saveFcmToken = async (req, res) => {
    try {
        const { id, token } = req.body;

        // Validate input
        if (!id || !token) {
            return res.status(400).json({
                result: [{ msg: "ID and token are required", success: "0" }]
            });
        }

        // Attempt to update the notification key in the database
        const success = await userModel.saveFcmToken(id, token);

        if (success) {
            // Token update successful
            return res.json({
                result: { msg: "FCM Token Updated Successfully!!!", success: "1" },
                success: '1'
            });
        } else {
            // No matching record found
            return res.status(404).json({
                result: [{ msg: "User not found", success: "0" }]
            });
        }
    } catch (error) {
        // Internal server error
        return res.status(500).json({
            result: [{ msg: "An error occurred", success: "0" }]
        });
    }
};













// Controller to get the user's summary (matches played, kills, amount won)
exports.getMySummary = async (req, res) => {
    const { user_id } = req.body;

    try {
        const summary = await userModel.getMySummary(user_id);
        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        res.json({  result: { 
                                maches_played: summary.matches_played,
                                total_kills: summary.total_kills,
                                amount_won: summary.amount_won,
                                success: '1'
                            },
                    "success": '1' },);
    } catch (error) {
        console.error("Error fetching user summary:", error);
        res.status(500).json({ error: "An error occurred while fetching the user summary." });
    }
};

// Controller to get the user's match statistics (title, fee, prize)
exports.getMyStatistics = async (req, res) => {
    const { user_id } = req.body;

    try {
        const statistics = await userModel.getMyStatistics(user_id);
        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        res.json({ result: statistics });
    } catch (error) {
        res.status(500).json({ error: "An error occurred while fetching the user statistics." });
    }
};

// Controller to get top players based on prize money
exports.getTopPlayers = async (req, res) => {
    try {
        const players = await userModel.getTopPlayers();
        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        res.json({ result: players });
    } catch (error) {
        res.status(500).json({ error: "An error occurred while fetching the top players." });
    }
};

// Controller to get referral summary for a given refer code
exports.getMyReferralsSummary = async (req, res) => {
    const { refer_code } = req.body;

    try {
        const referralSummary = await userModel.getMyReferralsSummary(refer_code);
        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        res.json({
            result: [{
                refer_code: referralSummary.refer_code,
                refer_points: referralSummary.refer_points,
                success: '1'
            }]
        });
    } catch (error) {
        res.status(500).json({ error: "An error occurred while fetching the referral summary." });
    }
};


// Controller to get referrals list for a given refer code
exports.getMyReferralsList = async (req, res) => {
    const { refer_code } = req.body;

    try {
        const referrals = await userModel.getMyReferralsList(refer_code);
        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        // Send output in the exact format as PHP
        res.json({ "result" : referrals.map(referral => ({
            refer_date: referral.refer_date,
            refer_status: referral.refer_status,
            fname: referral.fname,
            lname: referral.lname
        }))});
    } catch (error) {
        res.status(500).json({ error: "An error occurred while fetching the referrals list." });
    }
};

// Controller to get top leaders based on referral points
exports.getTopLeaders = async (req, res) => {
    try {
        const leaders = await userModel.getTopLeaders();
        res.setHeader('Content-Type', 'application/json; charset=utf-8');
        // Send output in the exact format as PHP
        res.json({ "result": leaders.map(leader => ({
            refer_points: leader.refer_points,
            fname: leader.fname,
            lname: leader.lname
        }))});
    } catch (error) {
        console.error("Error fetching top leaders:", error);
        res.status(500).json({ error: "An error occurred while fetching the top leaders." });
    }
};

exports.getRewards = async (req, res) => {
    const { username, reward_limits } = req.body;

    if (!username || !reward_limits) {
        return res.status(400).json({ msg: 'Missing username or reward_limits parameter.' });
    }

    try {
        const current_time = Math.floor(Date.now() / 1000);
        const rewardCount = await userModel.getRewardCount(username);

        if (rewardCount >= reward_limits) {
            const diff = (current_time + 86400 - (current_time % 86400)) - current_time;
            res.json({ result: [{ msg: diff, success: '0' }] });
        } else {
            res.json({ result: [{ msg: rewardCount, success: '1' }] });
        }
    } catch (error) {
        console.error(error);
        res.status(500).json({ msg: 'Internal Server Error' });
    }
};
