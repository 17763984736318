// src/models/authModel.js

const db = require('../config/db');

// Function to fetch user status and block status by refer code
exports.getUserByReferCode = async (refer) => {
    const query = "SELECT status, is_block FROM user_details WHERE refer = ?";
    try {
        const [results] = await db.query(query, [refer]);
        return results[0];
    } catch (error) {
        throw error;
    }
};

// Function to fetch user status and block status by mobile number
exports.getUserByMobile = (mobile) => {
    return new Promise((resolve, reject) => {
        const query = "SELECT status, is_block FROM user_details WHERE mobile = ?";
        db.query(query, [mobile], (error, results) => {
            if (error) {
                reject(error);
            } else {
                resolve(results.length ? results[0] : null);
            }
        });
    });
};

// Check if the device ID is blocked
exports.isDeviceBlocked = async (deviceId) => {
   try {
       const [blockedUser] = await db.query(
           "SELECT COUNT(id) as totrow FROM user_details WHERE device_id = ? AND is_block = '1'", 
           [deviceId]
       );
       return blockedUser[0].totrow > 0;
   } catch (error) {
       throw new Error('Error checking if device is blocked: ' + error.message);
   }
};

// Function to fetch user details by mobile, email, or username
exports.getUserByDetails = async (mobile, email, username) => {
    try {
        const [rows] = await db.query(
            "SELECT status, is_block FROM user_details WHERE mobile = ? OR email = ? OR username = ?", 
            [mobile, email, username]
        );
        return rows.length > 0 ? rows[0] : null;
    } catch (error) {
        throw new Error('Error checking existing user: ' + error.message);
    }
};

exports.updateOAuthToken = async (email, token, refreshToken) => {
    try {
      const query = `
        UPDATE tbl_user_master
        SET oauth_token = ?,
        refresh_token = ?
        WHERE email = ?
        LIMIT 1;
      `;
  
      const [result] = await db.query(query, [token, refreshToken, email]);
      return result.affectedRows > 0; 
    } catch (error) {
      throw new Error('Error updating OAuth token: ' + error.message);
    }
  };


exports.getVoucher = (voucher_code) => {
   return new Promise((resolve, reject) => {
       const query = "SELECT status, is_expired, amount, coin, transaction_id FROM tbl_gift_voucher WHERE voucher_code = ? AND is_del = '0'";
       
       db.query(query, [voucher_code], (error, results) => {
           if (error) {
               return reject(error);
           }
           resolve(results[0]); // Return first row
       });
   });
};


exports.getUserBalance = (user_id) => {
   return new Promise((resolve, reject) => {
       const query = "SELECT cur_balance FROM user_details WHERE id = ?";
       
       db.query(query, [user_id], (error, results) => {
           if (error) {
               return reject(error);
           }
           resolve(results[0]); // Return first row
       });
   });
};


exports.insertTransaction = (data) => {
   return new Promise((resolve, reject) => {
       const query = "INSERT INTO transaction_details SET ?";
       
       db.query(query, data, (error, results) => {
           if (error) {
               return reject(error);
           }
           resolve(results.insertId);
       });
   });
};

exports.updateUserBalance = (user_id, new_balance) => {
   return new Promise((resolve, reject) => {
       const query = "UPDATE user_details SET cur_balance = ? WHERE id = ?";
       
       db.query(query, [new_balance, user_id], (error, results) => {
           if (error) {
               return reject(error);
           }
           resolve(results.affectedRows);
       });
   });
};

exports.updateVoucherStatus = (voucher_code) => {
   return new Promise((resolve, reject) => {
       const query = "UPDATE tbl_gift_voucher SET status = '0' WHERE voucher_code = ?";
       
       db.query(query, [voucher_code], (error, results) => {
           if (error) {
               return reject(error);
           }
           resolve(results.affectedRows);
       });
   });
};

// Fetch user by email
exports.getUserByEmail = async (email) => {
    const [rows] = await db.query('SELECT id, email, totp, is_otp_validated FROM user_details WHERE email = ?', [email]);
    return rows;
};

// Update OTP for a user
exports.updateOtpByUserId = async (userId, otp) => {
    await db.query('UPDATE user_details SET totp = ? WHERE id = ?', [otp, userId]);
};

// Update OTP validation status
exports.validateOtpByUserId = async (userId) => {
    await db.query('UPDATE user_details SET is_otp_validated = 1 WHERE id = ?', [userId]);
};