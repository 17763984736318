// src/models/gameModel.js

const db = require('../config/db');
const { referer_bonus } = require('../config/constants');

// Function to get game list
exports.getGameList = async () => {
    const query = "SELECT id, title, banner, url, type FROM game_details ORDER BY id ASC";
    try {
        const results = await db.query(query);
        
        // Wrap the results in an object with a key "banners"
        return { matches: results[0] };
    } catch (error) {
        throw error;
    }
};

// Function to get game listings (slightly modified name)
exports.fetchBannerListings = async () => {
    const query = "SELECT id, title, banner, url, type FROM game_detailss ORDER BY id ASC";
    try {
        const results = await db.query(query);
        
        // Wrap the results in an object with a key "banners"
        return { banners: results[0] };
    } catch (error) {
        throw error;
    }
};



// Function to get match play details
exports.getMatchPlayDetails = async (userId, gameId) => {
    const query = `
        SELECT 
            t1.id, t1.game_id, t1.title, 
            FROM_UNIXTIME(t1.time - 19800, '%d/%m/%Y at %h:%i %p') AS time, 
            t1.prize_pool, t1.banner, t1.per_kill, t1.entry_fee, t1.entry_type, 
            t1.version, t1.map, t1.is_private, t1.match_type, t1.sponsored_by, 
            t1.match_status, t1.match_desc, t1.is_cancel, t1.cancel_reason, 
            t1.platform, t1.pool_type, t1.admin_share, t1.bet_status, 
            t2.match_id, t2.access_key, t2.room_id, t2.room_pass, t2.room_size, 
            t2.total_joined, COALESCE(t3.team, 0) AS team, COALESCE(t3.position, 0) AS position,
            t3.id AS joined_status, COUNT(t3.id) AS user_joined, t4.rules, t5.image
        FROM match_details t1 
        LEFT JOIN room_details t2 ON t1.id = t2.match_id
        LEFT JOIN participant_details t3 ON (t1.id = t3.match_id AND t3.user_id = ?)
        LEFT JOIN tbl_rules t4 ON t1.match_rules = t4.rule_id
        LEFT JOIN tbl_image t5 ON t1.banner = t5.img_id
        WHERE t1.game_id = ? AND t1.match_status = '0' AND t1.is_del = '0'
        GROUP BY t1.id ORDER BY t1.id ASC;
        `;

    try {
        const results = await db.query(query, [userId, gameId]);
        return results[0];
    } catch (error) {
        throw new Error(error);
    }
};


// Function to get live match details
exports.getMatchLiveDetails = async (userId, gameId) => {
    const query = `
        SELECT 
            t1.id, 
            t1.game_id, 
            t1.title, 
            FROM_UNIXTIME(t1.time - 19800, '%d/%m/%Y at %h:%i %p') AS time, 
            t1.pool_type, 
            t1.admin_share, 
            t1.prize_pool, 
            t1.match_desc, 
            t1.banner, 
            t1.per_kill, 
            t1.entry_fee, 
            t1.entry_type, 
            t1.version, 
            t1.map, 
            t1.is_private, 
            t1.match_type, 
            t1.sponsored_by, 
            t1.spectate_url, 
            t1.match_status, 
            t1.is_cancel, 
            t1.cancel_reason, 
            t1.platform, 
            t2.match_id, 
            t2.room_id, 
            t2.room_pass, 
            t2.room_size, 
            t2.total_joined, 
            t3.id AS joined_status, 
            COALESCE(t3.team, 0) AS team,  
            COALESCE(t3.position, 0) AS position,      
            t4.rules, 
            t5.title AS game, 
            t5.url, 
            t6.image
        FROM match_details t1 
        LEFT JOIN room_details t2 ON t1.id = t2.match_id
        LEFT JOIN participant_details t3 ON (t1.id = t3.match_id AND t3.user_id = ?)
        LEFT JOIN tbl_rules t4 ON t1.match_rules = t4.rule_id
        LEFT JOIN game_details t5 ON t1.game_id = t5.id
        LEFT JOIN tbl_image t6 ON t1.banner = t6.img_id
        WHERE t1.game_id = ? 
        AND t1.match_status = '1' 
        AND t1.is_del = '0'
        GROUP BY t1.id 
        ORDER BY t1.id ASC;
        `;

    try {
        const results = await db.query(query, [userId, gameId]);
        return results[0];
    } catch (error) {
        throw new Error(error);
    }
};

// Function to get match results
exports.getMatchResultDetails = async (userId, gameId) => {
    const query = `
        SELECT 
            t1.id, t1.game_id, t1.title, 
            FROM_UNIXTIME(t1.time - 19800, '%d/%m/%Y at %h:%i %p') AS time, 
            t1.pool_type, t1.admin_share, t1.prize_pool, t1.banner, 
            t1.per_kill, t1.entry_fee, t1.entry_type, t1.version, t1.map, 
            t1.is_private, t1.match_type, t1.sponsored_by, t1.match_notes, 
            t1.spectate_url, t1.match_status, t1.match_desc, t1.platform, 
            t2.total_joined, t3.id AS joined_status, t4.image
        FROM match_details t1 
        LEFT JOIN room_details t2 ON t1.id = t2.match_id
        LEFT JOIN participant_details t3 ON (t1.id = t3.match_id AND t3.user_id = ?)
        LEFT JOIN tbl_image t4 ON t1.banner = t4.img_id
        WHERE t1.game_id = ? AND t1.match_status = '3' AND t1.is_del = '0'
        GROUP BY t1.id ORDER BY t1.id DESC LIMIT 10;`;

    try {
        const results = await db.query(query, [userId, gameId]);
        return results[0];
    } catch (error) {
        throw new Error(error);
    }
};


// Function to get upcoming matches
exports.getMatchUpcomingDetails = async (userId) => {
    try {
        const query = `
            SELECT 
                t1.id, t1.game_id, t1.title, 
                FROM_UNIXTIME(t1.time - 19800, '%d/%m/%Y at %h:%i %p') AS time, 
                t1.prize_pool, t1.banner, t1.per_kill, t1.entry_fee, 
                t1.entry_type, t1.version, t1.map, t1.is_private, t1.match_type, 
                t1.sponsored_by, t1.match_status, t1.match_desc, t1.is_cancel, 
                t1.cancel_reason, t1.platform, t1.pool_type, t1.admin_share, 
                t1.bet_status, t2.match_id, t2.access_key, t2.room_id, t2.room_pass, 
                t2.room_size, t2.total_joined, t3.team, t3.position, t3.id AS joined_status, 
                COUNT(t3.id) AS user_joined, t4.rules, t5.image
            FROM match_details t1 
            LEFT JOIN room_details t2 ON t1.id = t2.match_id
            LEFT JOIN participant_details t3 ON (t1.id = t3.match_id AND t3.user_id = ?)
            LEFT JOIN tbl_rules t4 ON t1.match_rules = t4.rule_id
            LEFT JOIN tbl_image t5 ON t1.banner = t5.img_id
            WHERE t1.match_status = '0' 
                AND t1.is_del = '0' AND t3.user_id = ?
            GROUP BY t1.id ORDER BY t1.id ASC`;

        const results = await db.query(query, [userId, userId]);
        return results[0];
    } catch (error) {
        throw error;
    }
};


// Function to get ongoing matches
exports.getMatchOngoingDetails = async (userId) => {
    const query = `
        SELECT 
            t1.id, t1.game_id, t1.title, 
            FROM_UNIXTIME(t1.time - 19800, '%d/%m/%Y at %h:%i %p') AS time, 
            t1.pool_type, t1.admin_share, t1.prize_pool, t1.match_desc, t1.banner, 
            t1.per_kill, t1.entry_fee, t1.entry_type, t1.version, t1.map, 
            t1.is_private, t1.match_type, t1.sponsored_by, t1.spectate_url, 
            t1.match_status, t1.is_cancel, t1.cancel_reason, t1.platform, 
            t2.match_id, t2.room_id, t2.room_pass, t2.room_size, t2.total_joined, 
            t3.id AS joined_status, t3.team, t3.position, t4.rules, t5.title AS game, t5.url, t6.image
        FROM match_details t1 
        LEFT JOIN room_details t2 ON t1.id = t2.match_id
        LEFT JOIN participant_details t3 ON (t1.id = t3.match_id AND t3.user_id = ?)
        LEFT JOIN tbl_rules t4 ON t1.match_rules = t4.rule_id
        LEFT JOIN game_details t5 ON t1.game_id = t5.id
        LEFT JOIN tbl_image t6 ON t1.banner = t6.img_id
        WHERE t1.match_status = '1' AND t1.is_del = '0' AND t3.user_id = ?
        GROUP BY t1.id ORDER BY t1.id ASC;`;

    try {
        const results = await db.query(query, [userId, userId]);
        return results[0]; // Returns the first result if results exist
    } catch (error) {
        throw new Error(error);
    }
};


// Function to get completed matches
exports.getMatchCompletedDetails = async (userId) => {
    const query = `
        SELECT 
            t1.id, t1.game_id, t1.title, 
            FROM_UNIXTIME(t1.time - 19800, '%d/%m/%Y at %h:%i %p') AS time, 
            t1.pool_type, t1.admin_share, t1.prize_pool, t1.banner, 
            t1.per_kill, t1.entry_fee, t1.entry_type, t1.version, t1.map, 
            t1.is_private, t1.match_type, t1.sponsored_by, t1.match_notes, 
            t1.spectate_url, t1.match_status, t1.match_desc, t1.platform, 
            t2.total_joined, t3.id AS joined_status, t4.image
        FROM match_details t1 
        LEFT JOIN room_details t2 ON t1.id = t2.match_id
        LEFT JOIN participant_details t3 ON (t1.id = t3.match_id AND t3.user_id = ?)
        LEFT JOIN tbl_image t4 ON t1.banner = t4.img_id
        WHERE t1.match_status = '3' AND t1.is_del = '0' AND t3.user_id = ?
        GROUP BY t1.id ORDER BY t1.id DESC LIMIT 10`;

    try {
        const results = await db.query(query, [userId, userId]);
        return results[0];
    } catch (error) {
        throw new Error(error);
    }
};


// Function to get match timer details
exports.getMatchTimerDetails = async (matchId) => {
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

    const query = `SELECT time FROM match_details WHERE id = ?`;

    try {
        const results = await db.query(query, [matchId]);

        if (results.length > 0) {
            return {
                time: results[0][0].time,
                msg: currentTime + 19800,
                success: '1'
            };
        } else {
            return { success: '0', message: "Match not found" };
        }
    } catch (error) {
        throw new Error(error);
    }
};


// Function to get room details
exports.getRoomDetails = async (matchId) => {
    const query = `SELECT room_id, room_pass FROM room_details WHERE match_id = ?`;

    try {
        const results = await db.query(query, [matchId]);

        if (results.length > 0) {
            return {
                room_id: results[0][0].room_id,
                room_pass: results[0][0].room_pass,
                success: '1'
            };
        } else {
            return { success: '0', message: "Room details not found" };
        }
    } catch (error) {
        throw new Error(error);
    }
};


// Get participants of a match
exports.getMatchParticipants = async (matchId) => {
    const query = `
        SELECT id, user_id, pubg_id, team, position 
        FROM participant_details 
        WHERE match_id = ? AND is_canceled = '0' 
        ORDER BY team ASC`;

    try {
        const results = await db.query(query, [matchId]);
        return results[0];
    } catch (error) {
        throw new Error(error);
    }
};



// Get user's entries in a match
exports.getMyEntries = async (matchId, userId) => {
    const query = `
        SELECT *
        FROM participant_details 
        WHERE match_id = ? AND user_id = ? AND is_canceled = '0'`;

    try {
        const results = await db.query(query, [matchId, userId]);
        return results[0];
    } catch (error) {
        throw new Error(error);
    }
};


// Update a user's PUBG username in a match
exports.updateMyEntries = async (id, matchId, userId, pubgId) => {
    const checkQuery = `
        SELECT COUNT(id) AS totrow 
        FROM participant_details 
        WHERE match_id = ? AND pubg_id = ?`;

    try {
        const results = await db.query(checkQuery, [matchId, pubgId]);
        const count = results[0][0].totrow;

        if (count > 0) {
            return { msg: "This game username is already in use!", success: "0" };
        } else {
            const updateQuery = `
                UPDATE participant_details 
                SET pubg_id = ? 
                WHERE id = ? AND user_id = ?`;

            await db.query(updateQuery, [pubgId, id, userId]);
            return { msg: "Successfully updated game username.", success: "1"};
        }
    } catch (error) {
        throw new Error(error);
    }
};


// Get match winner (Position 1)
exports.getMatchWinner = async (matchId) => {
    const query = `
        SELECT id, user_id, pubg_id, kills, position, prize 
        FROM participant_details 
        WHERE match_id = ? AND position = '1' AND is_canceled = '0' 
        GROUP BY pubg_id 
        ORDER BY prize DESC`;

    try {
        const results = await db.query(query, [matchId]);
        return results[0];
    } catch (error) {
        throw new Error(error);
    }
};



// Get match runner-ups (Positions 2-10)
exports.getMatchRunnerup = async (matchId) => {
    const query = `
        SELECT id, user_id, pubg_id, kills, position, prize 
        FROM participant_details 
        WHERE match_id = ? AND is_canceled = '0' AND position BETWEEN '2' AND '10' 
        GROUP BY pubg_id 
        ORDER BY position ASC`;

    try {
        const results = await db.query(query, [matchId]);
        return results[0];
    } catch (error) {
        throw new Error(error);
    }
};



// Get match full result (All participants with position)
exports.getMatchFullResult = async (matchId) => {
    const query = `
        SELECT id, user_id, pubg_id, kills, prize 
        FROM participant_details 
        WHERE match_id = ? AND is_canceled = '0' 
        GROUP BY pubg_id 
        ORDER BY prize DESC`;

    try {
        const results = await db.query(query, [matchId]);
        return results[0];
    } catch (error) {
        throw new Error(error);
    }
};


exports.getMatchDetails = async (matchId) => {
   const [rows] = await db.query('SELECT entry_fee, entry_type, match_type FROM match_details WHERE id = ?', [matchId]);
   return rows[0];
};

exports.getPrivateMatchCode = async (matchId) => {
   const [rows] = await db.query('SELECT private_match_code FROM match_details WHERE id = ?', [matchId]);
   return rows[0].private_match_code;
};

exports.getParticipants = async (matchId, slots) => {
    try {
        // Extract all pubg_ids from the slots array
        const pubgIds = slots.map(slot => slot.pubgId);

        // Build the SQL query dynamically based on the number of pubg_ids
        const placeholders = pubgIds.map(() => '?').join(', ');
        const query = `
            SELECT *
            FROM participant_details
            WHERE match_id = ? AND pubg_id IN (${placeholders})
        `;

        // Combine matchId and all pubgIds into the parameters array
        const params = [matchId, ...pubgIds];

        // Execute the query
        const [rows] = await db.query(query, params);

        return rows; // Return all matching rows
    } catch (error) {
        console.error("Error fetching participants:", error.message);
        throw new Error("Failed to retrieve participants.");
    }
};


exports.checkSlotsTaken = async (matchId, slots) => {
    if (!slots || slots.length === 0) {
        throw new Error("Slots data is required and cannot be empty.");
    }

    const slotConditions = slots.map(() => "(team = ? AND position = ?)").join(" OR ");
    const query = `
        SELECT team, position
        FROM participant_details
        WHERE match_id = ? AND (${slotConditions}) AND is_canceled = "0"
    `;

    const slotParams = slots.flatMap(slot => [slot.team, slot.position]);
    const params = [matchId, ...slotParams];

    const [rows] = await db.query(query, params);
    // Check if any rows were returned
    if (rows.length > 0) {
        // Return the already taken slots
        return rows.map(row => `team: ${row.team}, position: ${row.position}`);
    } else {
        // Return an empty array if no slots are taken
        return [];
    }
};


exports.getReferral = async (username) => {
   const [rows] = await db.query('SELECT refer_code FROM referral_details WHERE username = ? AND refer_status = ?', [username, '0']);
   return rows;
};

exports.joinPaidMatch = async (matchId, userId, name, slots, entryFee, bonus, referCode = null) => {
    const connection = await db.getConnection();

    try {
        await connection.beginTransaction();

        // Fetch user balances
        const [[{ cur_balance, won_balance, bonus_balance }]] = await connection.query(
            'SELECT cur_balance, won_balance, bonus_balance FROM user_details WHERE id = ?',
            [userId]
        );

        // Total fee based on the number of slots
        const totalEntryFee = entryFee * slots.length;

        // Check if user has enough balance
        const checkBalance = (cur_balance - bonus_balance) + bonus * slots.length;
        if (checkBalance < totalEntryFee) {
            throw new Error("You do not have enough deposit or winning balance to participate.");
        }

        let newCurBalance = cur_balance;
        let newWonBalance = won_balance;
        let newBonusBalance = bonus_balance;

        // Deduct balances slot by slot
        for (let i = 0; i < slots.length; i++) {
            const slotBonus = bonus;

            if (newBonusBalance >= slotBonus) {
                const depositBalance = newCurBalance - (newWonBalance + newBonusBalance);
                const diff = depositBalance + slotBonus;

                if (diff >= entryFee) {
                    newWonBalance = newWonBalance;
                    newBonusBalance -= slotBonus;
                    newCurBalance -= entryFee;
                } else {
                    const fee = entryFee - diff;
                    newWonBalance -= fee;
                    newBonusBalance -= slotBonus;
                    newCurBalance -= entryFee;
                }
            } else {
                const depositBalance = newCurBalance - (newWonBalance + newBonusBalance);
                const diff = depositBalance + newBonusBalance;

                if (diff >= entryFee) {
                    newWonBalance = newWonBalance;
                    newBonusBalance = 0;
                    newCurBalance -= entryFee;
                } else {
                    const fee = entryFee - diff;
                    newWonBalance -= fee;
                    newBonusBalance = 0;
                    newCurBalance -= entryFee;
                }
            }

            // Insert slot into participant_details
            const { team, position, pubgId } = slots[i];
            await connection.query(
                'INSERT INTO participant_details (match_id, user_id, pubg_id, name, team, position) VALUES (?, ?, ?, ?, ?, ?)',
                [matchId, userId, pubgId, name, team, position]
            );
        }

        // Update room details
        await connection.query(
            'UPDATE room_details SET total_joined = total_joined + ? WHERE match_id = ?',
            [slots.length, matchId]
        );

        // // Update user balances
        await connection.query(
            'UPDATE user_details SET cur_balance = ?, won_balance = ?, bonus_balance = ? WHERE id = ?',
            [newCurBalance, newWonBalance, newBonusBalance, userId]
        );

        // Update referral if provided
        if (referCode) {
            // First, check if the referCode exists in referral_details and if it's not already availed (refer_status is 0)
            const [existingReferral] = await connection.query(
                'SELECT refer_points, refer_status FROM referral_details WHERE refer_code = ?',
                [referCode]
            );
        
            // If the referral exists and the refer_status is 0 (not availed)
            if (existingReferral.length > 0 && existingReferral[0].refer_status == 0) {
        
                // Update the bonus_balance in user_details table
                const [existingUser] = await connection.query(
                    'SELECT bonus_balance FROM user_details WHERE refer = ?',
                    [referCode]
                );
        
                if (existingUser.length > 0) {
                    const currentBonusBalance = existingUser[0].bonus_balance || 0;
                    const updatedBonusBalance = currentBonusBalance + referer_bonus;
        
                    // Update the bonus_balance in user_details
                    await connection.query(
                        'UPDATE user_details SET bonus_balance = ? WHERE refer = ?',
                        [updatedBonusBalance, referCode]
                    );
                } else {
                    console.error('User with the referCode not found.');
                    return;
                }
        
                // Update the refer_status to 1 in referral_details table after processing the referral
                await connection.query(
                    'UPDATE referral_details SET refer_status = 1 WHERE refer_code = ?',
                    [referCode]
                );

            } else if (existingReferral.length > 0 && existingReferral[0].refer_status === 1) {
                console.log('Referral has already been availed.');
            } else {
                console.error('Referral code not found.');
            }
        }
        
        
             

        await connection.commit();
        return { success: true };
    } catch (error) {
        await connection.rollback();
        return { success: false, message: error.message };
    } finally {
        connection.release();
    }
};


exports.joinFreeMatch = async (matchId, userId, name, slots) => {
    const connection = await db.getConnection();

    try {
        await connection.beginTransaction();

        // Insert each slot for the free match
        for (const { team, position, pubgId } of slots) {
            await connection.query(
                'INSERT INTO participant_details (match_id, user_id, pubg_id, name, team, position) VALUES (?, ?, ?, ?, ?, ?)',
                [matchId, userId, pubgId, name, team, position]
            );
        }

        // Update room details
        await connection.query(
            'UPDATE room_details SET total_joined = total_joined + ? WHERE match_id = ?',
            [slots.length, matchId]
        );

        await connection.commit();
        return { success: true };
    } catch (error) {
        await connection.rollback();
        return { success: false, message: error.message };
    } finally {
        connection.release();
    }
};

// Fetch match details by match ID
exports.getSlotsMatchDetails = async (match_id) => {
    const query = `
        SELECT * 
FROM room_details AS rd 
LEFT JOIN match_details AS md ON rd.match_id = md.id
WHERE md.id = ?;`;
    const [matchDetails] = await db.query(query, [match_id]);
    return matchDetails[0];
};

// Fetch participant details for a match
exports.getSlotsParticipantDetails = async (match_id) => {
    const query = `
        SELECT 
            m.username,
            mj.pubg_id,
            mj.team,
            mj.position,
            ma.match_type
        FROM 
            participant_details AS mj
        LEFT JOIN 
            user_details AS m ON m.id = mj.user_id
        LEFT JOIN 
            match_details AS ma ON ma.id = mj.match_id
        WHERE 
            mj.match_id = ?
        ORDER BY 
            mj.team ASC,
            mj.position ASC`;
    return await db.query(query, [match_id]);
};



