const db = require('../config/db');

exports.getContactDetails = async () => {
    const query = `
        SELECT title, phone, email, address, other, whatsapp_no, messenger_id,
               fb_follow, ig_follow, twitter_follow, youtube_follow
        FROM tbl_contact
    `;

    try {
        // Await the query results
        const results = await db.query(query);
        return results[0][0]; // Return the first row
    } catch (error) {
        // Handle and throw errors
        throw new Error(`Error fetching contact details: ${error.message}`);
    }
};

exports.getPolicyContent = async () => {
    const query = "SELECT content FROM tbl_privacy_policy";

    try {
        // Await the query results
        const results = await db.query(query);
        return results[0][0]; // Return the first row
    } catch (error) {
        // Handle and throw errors
        throw new Error(`Error fetching policy content: ${error.message}`);
    }
};


exports.getTermsContent = async () => {
    const query = "SELECT content FROM tbl_terms_conditions";

    try {
        // Await the query results
        const results = await db.query(query);
        return results[0][0]; // Return the first row
    } catch (error) {
        // Handle and throw errors
        throw new Error(`Error fetching terms content: ${error.message}`);
    }
};



exports.getAboutContent = async () => {
    const query = "SELECT content FROM tbl_about";

    try {
        // Execute the query and await the results
        const results = await db.query(query);
        return results[0][0]; // Return first row
    } catch (error) {
        // Handle and throw errors
        throw new Error(`Error fetching about content: ${error.message}`);
    }
};


exports.getFAQContent = async () => {
    const query = "SELECT content FROM tbl_faq";

    try {
        // Execute the query and await the results
        const results = await db.query(query);
        return results[0][0]; // Return first row
    } catch (error) {
        // Handle and throw errors
        throw new Error(`Error fetching FAQ content: ${error.message}`);
    }
};



exports.getLatestAnnouncements = async () => {
    try {
        const query = "SELECT title FROM announcement_details ORDER BY id DESC LIMIT 5";
        const [results] = await db.query(query); // Use promise-based query
        return results;
    } catch (error) {
        console.error("Database Query Error:", error); // Log the error
        throw new Error("Failed to fetch announcements from the database");
    }
};







exports.getLatestNotifications = async () => {
    const query = `
        SELECT id, title, message, image, url, created 
        FROM announcement_details 
        ORDER BY id DESC 
        LIMIT 10`;

    try {
        // Execute the query and await the results
        const results = await db.query(query);
        return results[0]; // Assuming you want the first result set
    } catch (error) {
        // Handle and throw errors
        throw new Error(`Error fetching latest notifications: ${error.message}`);
    }
};


exports.getUpdateDetails = () => {
    return db.query("SELECT id, force_update, whats_new, update_date, latest_version_name, latest_version_code, update_url FROM update_details")
        .then(([result]) => result[0]) // Destructure and return the first row
        .catch(error => { throw error; }); // Catch and throw the error to propagate
 };
 



exports.getSliderImages = (prod_id) => {
   return new Promise((resolve, reject) => {
       const query = "SELECT id, prod_id, prod_img FROM tbl_product_img WHERE prod_id = ? ORDER BY id ASC";

       db.query(query, [prod_id], (error, results) => {
           if (error) {
               return reject(error);
           }
           resolve(results); // Return all rows that match the prod_id
       });
   });
};

exports.getAllProducts = () => {
   return new Promise((resolve, reject) => {
       const query = "SELECT id, brand, name, image, price, price_discount, description, url FROM product_details ORDER BY id ASC";

       db.query(query, (error, results) => {
           if (error) {
               return reject(error);
           }
           resolve(results); // Return all rows from the product_details table
       });
   });
};
