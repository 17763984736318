// src/models/userModel.js

const db = require('../config/db');

// Get all users
// exports.getAllUsers = async () => {
//     try {
//         const [rows] = await db.query('SELECT * FROM user_details');
//         return rows;
//     } catch (error) {
//         throw new Error('Error fetching users: ' + error.message);
//     }
// };

// Get a user by ID
exports.getUserProfile = async (id) => {
    try {
        const [rows] = await db.query('SELECT * FROM user_details WHERE id = ?', [id]);
        return rows[0] || null;  // Return null if no user found
    } catch (error) {
        throw new Error('Error fetching user by ID: ' + error.message);
    }
};

// Check if the device ID is blocked
exports.isDeviceBlocked = async (deviceId) => {
    try {
        const [blockedUser] = await db.query(
            "SELECT COUNT(id) as totrow FROM user_details WHERE device_id = ? AND is_block = '1'", 
            [deviceId]
        );
        return blockedUser[0].totrow > 0;
    } catch (error) {
        throw new Error('Error checking if device is blocked: ' + error.message);
    }
};

// Check if the username, email, or mobile already exists
exports.isExistingUser = async (username, email, mobile) => {
    try {
        const [existingUser] = await db.query(
            "SELECT COUNT(id) as tot_row FROM user_details WHERE mobile = ? OR email = ? OR username = ?", 
            [mobile, email, username]
        );
        return existingUser[0].tot_row > 0 ? true : false;
    } catch (error) {
        throw new Error('Error checking existing user: ' + error.message);
    }
};

// Check if a referer code is valid
exports.isValidReferer = async (referer) => {
    try {
        const [validRefer] = await db.query(
            "SELECT COUNT(id) as tot_row1 FROM user_details WHERE refer = ? AND status = '1'", 
            [referer]
        );
        return validRefer[0].tot_row1 > 0;
    } catch (error) {
        throw new Error('Error checking referer validity: ' + error.message);
    }
};

// Retrieve total referrals for the referer and increment by 1
exports.getAndUpdateTotalReferrals = async (referer) => {
    try {
        const [totalReferedResult] = await db.query(
            "SELECT refered FROM user_details WHERE refer = ? AND status = '1'", 
            [referer]
        );
        const totalRefered = totalReferedResult[0].refered + 1;
        await db.query("UPDATE user_details SET refered = ? WHERE refer = ?", [totalRefered, referer]);
        return totalRefered;
    } catch (error) {
        throw new Error('Error getting and updating total referrals: ' + error.message);
    }
};

// Insert a new user and return the user ID
exports.insertUser = async (userData) => {
    try {
        const [insertUser] = await db.query("INSERT INTO user_details SET ?", userData);
        return insertUser.insertId;
    } catch (error) {
        throw new Error('Error inserting new user: ' + error.message);
    }
};

// Insert a referral details entry
exports.insertReferralDetails = async (referralData) => {
    try {
        await db.query("INSERT INTO referral_details SET ?", referralData);
    } catch (error) {
        throw new Error('Error inserting referral details: ' + error.message);
    }
};

// Update a user by ID
exports.updateUser = async (id, userData) => {
    const { fname, lname, gender, dob } = userData;
    try {
        await db.query('UPDATE user_details SET fname = ?, lname = ?, gender = ?, dob = ? WHERE id = ?', [fname, lname, gender, dob, id]);
        return { id, ...userData };  // Return updated user info
    } catch (error) {
        throw new Error('Error updating user: ' + error.message);
    }
};

// Delete a user by ID
exports.deleteUser = async (id) => {
    try {
        await db.query('DELETE FROM user_details WHERE id = ?', [id]);
        return { message: `User with ID ${id} deleted successfully` };
    } catch (error) {
        throw new Error('Error deleting user: ' + error.message);
    }
};

// Query to get user details for standard login
exports.getUserByCredentials = async (username, hashedPassword) => {
    try {
        const [rows] = await db.query(
            "SELECT * FROM user_details WHERE (mobile = ? OR email = ? OR username = ?) AND password = ?", 
            [username, username, username, hashedPassword]
        );
        return rows[0] || null; // Return null if no user found
    } catch (error) {
        throw new Error('Error fetching user by credentials: ' + error.message);
    }
};

// Query to get user details for social login
exports.getUserByUsername = async (username) => {
    try {
        const [rows] = await db.query(
            "SELECT * FROM user_details WHERE username = ?", 
            [username]
        );
        return rows[0] || null; // Return null if no user found
    } catch (error) {
        throw new Error('Error fetching user by username: ' + error.message);
    }
};

// Function to update the password based on mobile number
exports.updatePassword = async (id, hashedPassword) => {
    try {
        const [results] = await db.query('UPDATE user_details SET `password` = ? WHERE id = ?', [hashedPassword, id]);
        
        // Return true if a row was affected, or null if no rows were updated
        return results.affectedRows > 0 ? true : null;
    } catch (error) {
        throw new Error('Error updating password: ' + error.message);
    }
};

// Function to update the password based on mobile number
exports.updateNewPassword = async (email, hashedPassword) => {
    try {
        const [results] = await db.query('UPDATE user_details SET `password` = ? WHERE email = ?', [hashedPassword, email]);
        
        // Return true if a row was affected, or null if no rows were updated
        return results.affectedRows > 0 ? true : null;
    } catch (error) {
        throw new Error('Error updating password: ' + error.message);
    }
};

// Function to update the password based on mobile number
exports.resetPassword = async (email, hashedPassword) => {
    try {
        const [results] = await db.query('UPDATE user_details SET `password` = ? WHERE email = ?', [hashedPassword, email]);
        
        // Return true if a row was affected, or null if no rows were updated
        return results.affectedRows > 0 ? true : null;
    } catch (error) {
        throw new Error('Error updating password: ' + error.message);
    }
};

// Function to update the notification_key based on id
exports.saveFcmToken = async (id, token) => {
    try {
        const [results] = await db.query('UPDATE user_details SET `notification_key` = ? WHERE id = ?', [token, id]);

        // Return true if a row was affected, or null if no rows were updated
        return results.affectedRows > 0 ? true : null;
    } catch (error) {
        throw new Error('Error updating notification key: ' + error.message);
    }
};








// Get user's summary: matches played, total kills, total amount won
exports.getMySummary = async (userId) => {
    const query = `
        SELECT COUNT(DISTINCT(t1.match_id)) AS matches_played, 
               SUM(t1.kills) AS total_kills, 
               SUM(t1.prize) AS amount_won 
        FROM participant_details t1
        LEFT JOIN match_details t2 ON t1.match_id = t2.id AND t1.user_id = ?
        WHERE t1.user_id = ? AND t2.is_cancel = '0' AND t2.is_del = '0'`;

    try {
        const results = await db.query(query, [userId, userId]);
        return results[0][0]; // First row of the result set
    } catch (error) {
        throw error; // Rethrow for higher-level error handling
    }
};


// Get user's statistics: match title, entry fee, prize won
exports.getMyStatistics = async (userId) => {
    try {
        const query = `
            SELECT t1.id, t1.title, 
                   FROM_UNIXTIME(t1.time + 19800, '%d/%m/%Y at %h:%i %p') AS time, 
                   t1.entry_fee, SUM(t3.prize) AS prize
            FROM participant_details t3
            LEFT JOIN match_details t1 ON t3.match_id = t1.id AND t3.user_id = ?
            WHERE t3.user_id = ? AND t1.is_cancel = '0' AND t1.is_del = '0'
            GROUP BY t1.id
            ORDER BY t1.id DESC
            LIMIT 50`;

        // Execute the query and await the result
        const results = await db.query(query, [userId, userId]);

        // Return the results
        return results[0];
    } catch (error) {
        // Handle errors and rethrow
        throw new Error(`Error fetching user statistics: ${error.message}`);
    }
};



// Get top players based on prize money
exports.getTopPlayers = async () => {
    try {
        const query = `
            SELECT t2.username AS pubg_id, SUM(t3.prize) AS prize
            FROM participant_details t3
            LEFT JOIN user_details t2 ON t2.id = t3.user_id
            LEFT JOIN match_details t1 ON t3.match_id = t1.id
            WHERE t1.is_cancel = '0' AND t1.is_del = '0'
            GROUP BY t2.username
            ORDER BY SUM(t3.prize) DESC
            LIMIT 10`;

        // Execute the query and await the result
        const results = await db.query(query);

        // Return the results
        return results[0];
    } catch (error) {
        // Handle errors and rethrow
        throw new Error(`Error fetching top players: ${error.message}`);
    }
};


// Get referral summary for a given refer code
exports.getMyReferralsSummary = async (referCode) => {
    const query = `
        SELECT COUNT(refer_code) AS refer_code, SUM(refer_points) AS refer_points
        FROM referral_details
        WHERE refer_code = ?`;

    try {
        const results = await db.query(query, [referCode]);
        return results[0][0]; // Return the first result
    } catch (error) {
        throw new Error(`Error fetching referrals summary: ${error.message}`);
    }
};


// Get a list of referrals for a given refer code
exports.getMyReferralsList = async (referCode) => {
    try {
        const query = `
            SELECT t1.refer_date, t1.refer_status, t2.fname, t2.lname
            FROM referral_details t1
            LEFT JOIN user_details t2 ON t1.username = t2.username
            WHERE t1.refer_code = ?`;

        // Await the result of the database query
        const results = await db.query(query, [referCode]);

        // Return the fetched results
        return results[0];
    } catch (error) {
        // Handle errors
        throw new Error(`Error fetching referrals list: ${error.message}`);
    }
};


// Get top leaders based on referral points
exports.getTopLeaders = async () => {
    const query = `
        SELECT SUM(t1.refer_points) AS refer_points, t2.fname, t2.lname
        FROM referral_details t1
        LEFT JOIN user_details t2 ON t1.refer_code = t2.refer
        GROUP BY t1.refer_code
        ORDER BY refer_points DESC LIMIT 10`;

    try {
        // Execute the query and return the results
        const results = await db.query(query);
        return results[0]; // Assuming you want the first result set
    } catch (error) {
        // Throw error if something goes wrong
        throw new Error(`Error fetching top leaders: ${error.message}`);
    }
};


exports.updateReferrer = async (referCode, bonus) => {
    await db.query('UPDATE user_details SET cur_balance = cur_balance + ?, bonus_balance = bonus_balance + ? WHERE refer = ?', [bonus, bonus, referCode]);
    await db.query('UPDATE referral_details SET refer_points = ?, refer_status = ?, refer_date = ? WHERE refer_code = ?', [bonus, '1', new Date(), referCode]);
};