
const express = require('express');
const router = express.Router();
const miscController = require('../controllers/miscController');
const auth = require('../middleware/auth');
const key_auth = require('../middleware/key_auth');

router.post('/get_update_app',key_auth, miscController.getUpdateApp);

router.post('/get_contact_us',auth, miscController.getContactUs);
router.post('/get_privacy_policy',auth, miscController.getPrivacyPolicy);
router.post('/get_terms_conditions',auth, miscController.getTermsConditions);
router.post('/get_about_us',auth, miscController.getAboutUs);
router.post('/get_faq',auth, miscController.getFAQ);
router.post('/get_announcement',auth, miscController.getAnnouncement);
router.post('/get_notification',auth, miscController.getNotification);
router.post('/get_slider',auth, miscController.getSlider);
router.post('/get_products',auth, miscController.getProducts);

module.exports = router;