const express = require('express');
const dotenv = require('dotenv');
const passport = require('passport');
const path = require('path');
const session = require('express-session');
const cors = require('cors');
const helmet = require('helmet'); // Adds secure HTTP headers
const rateLimit = require('express-rate-limit'); // For rate limiting
const xssClean = require('xss-clean'); // Prevent XSS attacks
const hpp = require('hpp'); // Prevent HTTP Parameter Pollution
const compression = require('compression'); // Reduce response size
const mongoSanitize = require('express-mongo-sanitize'); // Prevent NoSQL injection

// Import routes
const authRoutes = require('./src/routes/authRoutes');
const gameRoutes = require('./src/routes/gameRoutes');
const lotteryRoutes = require('./src/routes/lotteryRoutes');
const transactionRoutes = require('./src/routes/transactionRoutes');
const userRoutes = require('./src/routes/userRoutes');
const rewardRoutes = require('./src/routes/rewardRoutes');
const miscRoutes = require('./src/routes/miscRoutes');

dotenv.config(); // Load environment variables

const app = express();

// === Middleware ===
app.use(express.json());

// Enable CORS with specific configuration for added security
app.use(
    cors({
        origin: process.env.ALLOWED_ORIGINS?.split(',') || '*', // Restrict origins
        methods: ['GET', 'POST', 'PUT', 'DELETE'], // Allowed HTTP methods
        credentials: true, // Allow cookies
    })
);

// Add security headers
app.use(helmet());

// Rate limiter to prevent DDoS
// const limiter = rateLimit({
//     windowMs: 5 * 60 * 1000, // 5 minutes
//     max: 100, // Limit each IP to 100 requests per windowMs
//     message: 'Too many requests from this IP, please try again later.',
// });
// app.use(limiter);

// Protect against NoSQL injection attacks
app.use(mongoSanitize());

// Prevent XSS attacks
app.use(xssClean());

// Prevent HTTP Parameter Pollution
app.use(hpp());

// Compress responses
app.use(compression());

// Use secure cookies in sessions
app.use(
    session({
        secret: process.env.SESSION_SECRET || 'default_secret',
        resave: false,
        saveUninitialized: true,
        cookie: {
            httpOnly: true, // Prevent client-side JS from accessing the cookie
            secure: process.env.NODE_ENV === 'production', // Use secure cookies in production
            sameSite: 'strict', // Protect against CSRF
        },
    })
);

// Initialize Passport for authentication
app.use(passport.initialize());
app.use(passport.session());

// Serve static files securely
app.use(express.static(path.join(__dirname, 'public'), { maxAge: '1d' }));

// Middleware for parsing JSON
// app.use(express.json({ limit: '10kb' })); // Limit JSON payload size to prevent abuse

// === Routes ===
app.use('/api/v1/auth', authRoutes);
app.use('/api/v1/games', gameRoutes);
app.use('/api/v1/lottery', lotteryRoutes);
app.use('/api/v1/transactions', transactionRoutes);
app.use('/api/v1/users', userRoutes);
app.use('/api/v1/rewards', rewardRoutes);
app.use('/api/v1/misc', miscRoutes);

// === Error Handling ===
// Catch 404 and forward to error handler
app.use((req, res, next) => {
    res.status(404).json({ status: 'fail', message: 'Resource not found' });
});

// Global error handling middleware
app.use((err, req, res, next) => {
    console.error(err.stack);
    res.status(500).json({ status: 'error', message: 'Internal server error' });
});

// === Server Configuration ===
const PORT = process.env.PORT || 3000;

// Start the server
app.listen(PORT, '0.0.0.0', () => {
    console.log(`Server running securely on port ${PORT}`);
});
