// src/routes/userRoutes.js

const express = require('express');
const router = express.Router();
const userController = require('../controllers/userController');
const jwt_auth = require('../middleware/jwt_auth');
const auth = require('../middleware/auth');
const key_auth = require('../middleware/key_auth');

router.post('/user_register',key_auth, userController.createUser);

router.post('/user_login',key_auth, userController.userLogin);

router.post('/forgot_password',key_auth, userController.forgotPassword);

router.post('/reset_password',key_auth, userController.resetPassword);

router.post('/new_password',key_auth, userController.newPassword);



router.post('/get_user_profile',auth, userController.getUserProfile);

router.put('/update_user_profile',auth, userController.updateUser);


router.post('/get_my_summary',auth, userController.getMySummary);

router.post('/get_my_statistics',auth, userController.getMyStatistics);

router.post('/get_my_referrals_summary',auth, userController.getMyReferralsSummary);

router.post('/get_my_referralsList',auth, userController.getMyReferralsList);

router.post('/get_top_players',auth, userController.getTopPlayers);

router.post('/get_top_leaders',auth, userController.getTopLeaders);

router.post('/save_fcm_token',auth, userController.saveFcmToken);




// router.get('/verify_refer', userController.verifyRefer);

// router.get('/verify_mobile', userController.verifyMobile);

// router.get('/verify_register', userController.verifyRegister);

// router.get('/get_game_list', userController.getGameList);

// router.get('/retrive_game_list', userController.retrieveGameList);

// router.get('/get_match_play', userController.getMatchPlay);

// router.get('/get_match_live', userController.getMatchLive);

// router.get('/get_match_result', userController.getMatchResult);

// router.get('/get_match_upcoming', userController.getMatchUpcoming);

// router.get('/get_match_ongoing', userController.getMatchOngoing);

// router.get('/get_match_completed', userController.getMatchCompleted);

// router.get('/get_match_timer', userController.getMatchTimer);

// router.get('/get_room_details', userController.getRoomDetails);

// router.get('/get_lottery_list', userController.getLotteryList);

// router.get('/get_lottery_participant', userController.getLotteryParticipant);

// router.get('/get_lottery_my', userController.getLotteryMy);

// router.get('/get_lottery_result', userController.getLotteryResult);

// router.get('/join_lottery', userController.joinLottery);

// router.get('/get_match_participants', userController.getMatchParticipants);

// router.get('/get_my_entries', userController.getMyEntries);

// router.get('/update_my_entries', userController.updateMyEntries);

// router.get('/get_match_winner', userController.getMatchWinner);

// router.get('/get_match_runnerup', userController.getMatchRunnerup);

// router.get('/get_match_full_result', userController.getMatchFullResult);

// router.get('/get_my_summary', userController.getMySummary);

// router.get('/get_my_statistics', userController.getMyStatistics);

// router.get('/get_my_transactions', userController.getMyTransactions);

// router.get('/get_myoff_transactions', userController.getMyoffTransactions);

// router.get('/get_my_referrals_summary', userController.getMyReferralsSummary);

// router.get('/get_my_referralsList', userController.getMyReferralsList);

// router.get('/get_my_rewards_summary', userController.getMyRewardsSummary);

// router.get('/get_my_rewardsList', userController.getMyRewardsList);

// router.get('/get_top_rewards', userController.getTopRewards);

// router.get('/join_match', userController.joinMatch);

// router.get('/get_contact_us', userController.getContactUs);

// router.get('/get_privacy_policy', userController.getPrivacyPolicy);

// router.get('/get_terms_conditions', userController.getTermsConditions);

// router.get('/get_about_us', userController.getAboutUs);

// router.get('/get_faq', userController.getFAQ);

// router.get('/get_announcement', userController.getAnnouncement);

// router.get('/get_notification', userController.getNotification);

// router.get('/get_update_app', userController.getUpdateApp);

// router.get('/verify_card', userController.verifyCard);

// router.get('/get_slider', userController.getSlider);

// router.get('/get_products', userController.getProducts);

// router.get('/get_rewards', userController.getRewards);

// router.get('/add_reward', userController.addReward);

// router.get('/add_money', userController.AddMransaction);

// router.get('/add_transaction', userController.addTransaction);

// router.get('/get_redeem_coins', userController.getRedeemCoins);

// router.get('/get_add_coins', userController.getAddCoins);

// router.get('/get_tutorial', userController.getTutorial);

module.exports = router;
