
const express = require('express');
const router = express.Router();
const gameController = require('../controllers/gameController');
const auth = require('../middleware/auth');

router.post('/retrieve_game_list',auth, gameController.retrieveGameList);
router.post('/retrieve_banner_list',auth, gameController.retrieveBannerList);

router.post('/get_match_play',auth, gameController.getMatchPlay);
router.post('/get_match_live',auth, gameController.getMatchLive);
router.post('/get_match_result',auth, gameController.getMatchResult);

router.post('/get_match_upcoming',auth, gameController.getMatchUpcoming);
router.post('/get_match_ongoing',auth, gameController.getMatchOngoing);
router.post('/get_match_completed',auth, gameController.getMatchCompleted);

router.post('/get_match_timer',auth, gameController.getMatchTimer);
router.post('/get_room_details',auth, gameController.getRoomDetails);

router.post('/get_my_entries',auth, gameController.getMyEntries);
router.post('/update_my_entries',auth, gameController.updateMyEntries);
router.post('/get_match_participants',auth, gameController.getMatchParticipants);


router.post('/get_match_winner',auth, gameController.getMatchWinner);
router.post('/get_match_runnerup',auth, gameController.getMatchRunnerup);
router.post('/get_match_full_result',auth, gameController.getMatchFullResult);

router.post('/join_match',auth, gameController.joinMatch);

router.post('/get_slots',auth, gameController.getSlots);

module.exports = router;