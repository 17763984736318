const db = require('../config/db');

// Function to get the list of lotteries
exports.getLotteryList = async (userId) => {
    try {
        const currentTime = Math.floor(Date.now() / 1000) + 19800; // Add offset for timezone

        const query = `
            SELECT t1.id, t1.title, 
                FROM_UNIXTIME(t1.time + 19800, '%d/%m/%Y at %h:%i %p') AS time, 
                t1.time AS timestamp, 
                t1.fee, t1.prize, t1.size, t1.joined AS total_joined, 
                COUNT(DISTINCT t2.id) AS my_number, 
                t3.rules, t4.image, 
                ? AS currenttime
            FROM lottery_details t1
            LEFT JOIN result_details t2 ON (t1.id = t2.lottery_id AND t2.user_id = ?)
            LEFT JOIN tbl_rules t3 ON t1.rules = t3.rule_id
            LEFT JOIN tbl_image t4 ON t1.cover = t4.img_id
            WHERE t1.status = '0'
            GROUP BY t1.id 
            ORDER BY t1.id DESC`;

        // Execute the query with async/await
        const results = await db.query(query, [currentTime, userId]);

        // Return the fetched results
        return results[0];

    } catch (error) {
        // Handle errors
        throw new Error(`Error fetching lottery list: ${error.message}`);
    }
};

// Function to get lottery participants
exports.getLotteryParticipant = async (lotteryId) => {
    try {
        const query = `
            SELECT id, name, lottery_no 
            FROM result_details 
            WHERE lottery_id = ? 
            ORDER BY lottery_no ASC`;

        // Await the execution of the query
        const results = await db.query(query, [lotteryId]);

        // Return the fetched results
        return results[0];
    } catch (error) {
        // Handle errors
        throw new Error(`Error fetching lottery participants: ${error.message}`);
    }
};


// Function to get a user's lottery entries
exports.getLotteryMy = async (lotteryId, userId) => {
    try {
        const query = `
            SELECT id, name, lottery_no 
            FROM result_details 
            WHERE lottery_id = ? AND user_id = ?`;

        // Execute the query and await the results
        const results = await db.query(query, [lotteryId, userId]);

        // Return the results
        return results[0]; // Return all matching rows
    } catch (error) {
        // Handle any errors
        throw new Error(`Error fetching user's lottery data: ${error.message}`);
    }
};




// Function to get the lottery results
exports.getLotteryResult = async () => {
    try {
        const query = `
            SELECT t1.id, t1.title, 
                FROM_UNIXTIME(t1.time + 19800, '%d/%m/%Y') AS time, 
                t1.prize, t2.name, t2.lottery_no, t3.image
            FROM lottery_details t1 
            LEFT JOIN result_details t2 ON t1.id = t2.lottery_id
            LEFT JOIN tbl_image t3 ON t1.cover = t3.img_id
            WHERE t2.win = '1' AND t1.status = '1'
            ORDER BY t1.id DESC 
            LIMIT 0, 5`;

        // Await the execution of the query
        const results = await db.query(query);

        // Return the fetched results
        return results[0];
    } catch (error) {
        // Handle errors
        throw new Error(`Error fetching lottery results: ${error.message}`);
    }
};

// lotteryModel.js

exports.getLotteryDetailsById = async (lotteryId) => {
    try {
        const query = `
            SELECT size
            FROM lottery_details 
            WHERE id = ?`;

        // Execute the query and return the result
        const results = await db.query(query, [lotteryId]);

        // Return the first record if it exists
        if (results.length > 0) {
            return results[0][0];
        } else {
            throw new Error("Lottery not found.");
        }
    } catch (error) {
        throw new Error(`Error fetching lottery details: ${error.message}`);
    }
};



// Function to join a lottery
exports.joinLottery = async (lotteryId, userId, name, entryFee, bonus, roomSize) => {
    try {
        // Get the user's current balance, bonus, and won balance
        const query1 = `
            SELECT cur_balance, won_balance, bonus_balance 
            FROM user_details 
            WHERE id = ?`;

        const userResults = await db.query(query1, [userId]);
        const user = userResults[0][0];
        const checkBalance = (user.cur_balance - user.bonus_balance) + bonus;

        if (checkBalance >= entryFee) {
            // Process the joining logic
            const query2 = `
                SELECT size, joined 
                FROM lottery_details 
                WHERE id = ?`;

            const lotteryResults = await db.query(query2, [lotteryId]);
            const lottery = lotteryResults[0][0];
            const totalJoined = lottery.joined + 1;

            if (totalJoined <= roomSize) {
                // Join the lottery if space is available
                const data1 = {
                    lottery_id: lotteryId,
                    user_id: userId,
                    name: name
                };

                const data2 = {
                    cur_balance: user.cur_balance - entryFee,
                    won_balance: user.won_balance,
                    bonus_balance: user.bonus_balance - bonus
                };

                const data3 = {
                    joined: totalJoined
                };

                // Update the database
                await db.query('INSERT INTO result_details SET ?', data1);
                await db.query('UPDATE user_details SET ? WHERE id = ?', [data2, userId]);
                await db.query('UPDATE lottery_details SET ? WHERE id = ?', [data3, lotteryId]);

                return { msg: "You successfully registered for this draw.", success: "1" };
            } else {
                return { msg: "Lottery room is full.", success: "0" };
            }
        } else {
            return { msg: "Not enough balance to participate.", success: "0" };
        }
    } catch (error) {
        throw new Error(`Error joining lottery: ${error.message}`);
    }
};
