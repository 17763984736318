
const express = require('express');
const router = express.Router();
const lotteryController = require('../controllers/lotteryController');
const auth = require('../middleware/auth');

router.post('/get_lottery_list',auth, lotteryController.getLotteryList);
router.post('/get_lottery_participant',auth, lotteryController.getLotteryParticipant);
router.post('/get_lottery_my',auth, lotteryController.getLotteryMy);
router.post('/get_lottery_result',auth, lotteryController.getLotteryResult);
router.post('/join_lottery',auth, lotteryController.joinLottery);

module.exports = router;