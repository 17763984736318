const jwt = require('jsonwebtoken');
const NodeCache = require('node-cache'); // In-memory caching
const JWT_SECRET = process.env.JWT_SECRET || 'm!Q3P$V%8Xy*Z@Lr7jF';

const cache = new NodeCache({ stdTTL: 300, checkperiod: 60 }); // Cache tokens for 5 minutes

const jwt_auth = (req, res, next) => {
    // Extract Bearer token from the Authorization header
    const token = req.header('Authorization')?.split(' ')[1];
    if (!token) {
        return res.status(401).json({ status: "5", message: 'Access denied. No token provided.' });
    }

    try {
        // Check if the token is already cached
        const cachedUser = cache.get(token);
        if (cachedUser) {
            req.user = cachedUser; // Attach cached user data to req.user
            return next(); // Skip token verification
        }

        // Verify the JWT token
        const decoded = jwt.verify(token, JWT_SECRET);

        // Cache the decoded token data
        cache.set(token, decoded);

        // Attach decoded token data to req.user
        req.user = decoded;

        next();
    } catch (error) {
        res.status(400).json({ status: "5", message: 'Invalid token' });
    }
};

module.exports = jwt_auth;
