const nodemailer = require('nodemailer');
require('dotenv').config(); 

// Create a transporter using SMTP details from .env
const transporter = nodemailer.createTransport({
    host: process.env.SMTP_HOST,
    port: process.env.SMTP_PORT,
    secure: process.env.SMTP_PORT == 465,
    auth: {
        user: process.env.SMTP_USERNAME,
        pass: process.env.SMTP_PASSWORD
    }
});

// Reusable function to send emails
const sendEmail = async (to, subject, htmlContent) => {
    try {
        await transporter.sendMail({
            from: `"Bharat Battle" <${process.env.SMTP_USERNAME}>`, // Sender address
            to, // Recipient address
            subject, // Subject line
            html: htmlContent // HTML content
        });
    } catch (error) {
        console.error('Error sending email:', error.message);
        throw new Error('Failed to send email'); // Re-throw for higher-level handling
    }
};

module.exports = sendEmail;
