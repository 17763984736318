// src/controllers/authController.js
const authModel = require('../models/authModel');
const sendEmail = require('../utils/email'); // Email utility

const passport = require('passport');
const { Strategy: GoogleStrategy } = require('passport-google-oauth20');
const { OAuth2Client, auth } = require('google-auth-library');
const dotenv = require('dotenv');
const axios = require('axios');
const querystring = require('querystring');
const path = require('path');

// Controller for verifying refer code
exports.verifyRefer = async (req, res) => {
    try {
        const { refer } = req.body;
        
        if (!refer) {
            return res.status(400).json({ msg: "Refer code is required", success: "0" });
        }
        
        // Fetch user details by refer code
        const user = await authModel.getUserByReferCode(refer);
        let response;
        if (user) {
            if (user.status == 1 && user.is_block == 0) {
                response = { msg: "Promo code Applied.", success: "1" };
            } else if (user.status === 0 || user.is_block === 1) {
                response = { msg: "This promo code has been expired.", success: "0" };
            }
        } else {
            response = { msg: "Invalid promo code.", success: "0" };
        }
        
        // Return the JSON response in the exact format required
        res.json({ result: [response] });
    } catch (error) {
        console.error("Error in verifyRefer:", error);
        res.status(500).json({
            result: [{ msg: "An error occurred while verifying the refer code.", success: "0" }]
        });
    }
};

// Controller for verifying mobile number
exports.verifyMobile = async (req, res) => {
    try {
        const { mobile } = req.body;
        
        if (!mobile) {
            return res.status(400).json({ result: [{ msg: "Mobile number is required", success: "0" }] });
        }
        
        // Fetch user details by mobile number
        const user = await authModel.getUserByMobile(mobile);
        
        let response;
        if (user) {
            if (user.status === 1 && user.is_block === 0) {
                response = { msg: "Success", success: "1" };
            } else if (user.status === 0) {
                response = { msg: "Your account has been locked.", success: "0" };
            } else if (user.is_block === 1) {
                response = { msg: "Your device has been locked.", success: "0" };
            }
        } else {
            response = { msg: "User mobile number not found. Try to register with same number.", success: "0" };
        }
        
        // Return the JSON response in the exact format required
        res.json({ result: [response] });
    } catch (error) {
        console.error("Error in verifyMobile:", error);
        res.status(500).json({
            result: [{ msg: "An error occurred while verifying the mobile number.", success: "0" }]
        });
    }
};

// Controller for verifying registration details
exports.verifyRegister = async (req, res) => {
    try {
        const { mobile, email, username, device_id } = req.body;
        
        if (!mobile) {
            return res.status(400).json({ result: [{ msg: "Mobile number is required", success: "0" }] });
        }
        
        // Check if device is blocked
        const isBlockedDevice = await authModel.isDeviceBlocked(device_id);
        
        if (isBlockedDevice) {
            return res.json({
                result: [{ msg: "Your device has been locked. Please contact us.", success: "0" }]
            });
        }
        
        // Fetch user details by mobile, email, or username
        const user = await authModel.getUserByDetails(mobile, email, username);
        
        let response;
        // if (user) {
        //     if (user.status === 1 && user.is_block === 0) {
        //         response = { msg: "This username, email id or mobile number already used.", success: "0" };
        //     } else if (user.status === 0) {
        //         response = { msg: "Your account has been locked. Please contact us", success: "0" };
        //     } else if (user.is_block === 1) {
        //         response = { msg: "Your device has been locked. Please contact us.", success: "0" };
        //     }
        // } else {
        response = { msg: "Success", success: "1" };
        // }
        
        // Return the JSON response in the exact format required
        res.json({ result: response });
    } catch (error) {
        console.error("Error in verifyRegister:", error);
        res.status(500).json({
            result: [{ msg: "An error occurred while verifying registration details.", success: "0" }]
        });
    }
};

exports.verifyCard = async (req, res) => {
    const { user_id, voucher_code } = req.body;
    
    if (!user_id || !voucher_code) {
        return res.status(400).json({ result: [{ msg: 'Missing user_id or voucher_code.', success: '0' }] });
    }
    
    try {
        const voucher = await authModel.getVoucher(voucher_code);
        
        if (!voucher) {
            return res.status(404).json({ result: [{ msg: 'Invalid gift card voucher.', success: '0' }] });
        }
        
        if (voucher.status === 0) {
            return res.json({ result: [{ msg: 'This gift card voucher already used.', success: '0' }] });
        } else if (voucher.is_expired === 1) {
            return res.json({ result: [{ msg: 'This gift card voucher was expired.', success: '0' }] });
        } else {
            const current_time = Math.floor(Date.now() / 1000);
            const order_id = `${current_time}${user_id}`;
            const play_coins = voucher.coin;
            
            const userBalance = await authModel.getUserBalance(user_id);
            const new_balance = userBalance.cur_balance + play_coins;
            
            const transactionData = {
                user_id,
                order_id,
                payment_id: voucher.transaction_id,
                req_amount: voucher.amount,
                coins_used: voucher.coin,
                getway_name: 'Gift Card',
                remark: 'Added From Gift Card',
                type: '1',
                date: current_time,
                status: '1'
            };
            
            await authModel.insertTransaction(transactionData);
            await authModel.updateUserBalance(user_id, new_balance);
            await authModel.updateVoucherStatus(voucher_code);
            
            res.json({ result: [{ msg: 'Your request has been successfully approved. Please check your wallet.', success: '1' }] });
        }
    } catch (error) {
        console.error(error);
        res.status(500).json({ result: [{ msg: 'Internal Server Error', success: '0' }] });
    }
};


// Google OAuth Strategy Configuration
passport.use(new GoogleStrategy({
    clientID: process.env.GOOGLE_CLIENT_ID,
    clientSecret: process.env.GOOGLE_CLIENT_SECRET,
    callbackURL: process.env.GOOGLE_REDIRECT_URI,
    accessType: 'offline',  // This ensures a refresh token is returned
    prompt: 'consent',      // Forces Google to return a refresh token every time
}, async (accessToken, refreshToken, profile, done) => {
    return done(null, { profile, accessToken, refreshToken });
}));


// Serialize user into the session
passport.serializeUser((user, done) => {
    done(null, user);
});

// Deserialize user from the session
passport.deserializeUser((user, done) => {
    done(null, user);
});


// Google OAuth2.0 Authentication Route
exports.googleAuth = passport.authenticate('google', {
    scope: [
        'profile',
        'email',
        'https://www.googleapis.com/auth/firebase.messaging', // Firebase Messaging scope
    ],
    accessType: 'offline',
    prompt: 'consent',
});



const client = new OAuth2Client(
    process.env.GOOGLE_CLIENT_ID,
    process.env.GOOGLE_CLIENT_SECRET,
    process.env.GOOGLE_REDIRECT_URI
);

exports.googleAuthCallback = async (req, res) => {
    try {
        const { code } = req.query; // Code from the Google OAuth response

        if (!code) {
            // Respond with an error message if the code is not provided
            return res.status(400).send("Error: Authorization code not provided.");
        }

        // Exchange the authorization code for an access token
        const { tokens } = await client.getToken({
            code,  // Authorization code received from Google
            redirect_uri: process.env.GOOGLE_REDIRECT_URI, // Your redirect URI
        });

        // If tokens are received, extract the access token, id token, and refresh token
        const { access_token, id_token, refresh_token } = tokens;

        // Verify the ID token to get the user's profile
        const ticket = await client.verifyIdToken({
            idToken: id_token,
            audience: process.env.GOOGLE_CLIENT_ID, // Ensure this matches the client ID
        });

        const profile = ticket.getPayload(); // The user's profile information from Google

        // Save or update the token in the database
        const { email } = profile;

        const updated = await authModel.updateOAuthToken(email, access_token, refresh_token);

        if (!updated) {
            // Respond with an error message if the user is not found in the database
            return res.status(404).send("Error: User not found in the database.");
        }

        // Respond with a success message
        return res.status(200).send("Success: Google authentication completed.");
    } catch (error) {
        console.error('Error in Google Auth Callback:', error.message);
        // Respond with a generic error message
        res.status(400).send(`Error: ${error.message}`);
    }
};

exports.refreshAccessToken = async (req, res) => {
    try {
        const { refresh_token } = req.body; // Extract refresh token from request body
        
        if (!refresh_token) {
            return res.status(400).json({ message: 'Refresh token is missing.' });
        }

        // Set the refresh token in the OAuth2 client
        client.setCredentials({ refresh_token });

        // Use the refresh token to get a new access token
        const { credentials } = await client.refreshAccessToken(); // Obtain new tokens
        const newAccessToken = credentials.access_token;

        // Get the user profile using the new access token
        client.setCredentials({ access_token: newAccessToken });

        // Use Axios or the Google API client to fetch the user profile
        const userInfoResponse = await axios.get(
            'https://www.googleapis.com/oauth2/v2/userinfo',
            {
                headers: { Authorization: `Bearer ${newAccessToken}` },
            }
        );

        const profile = userInfoResponse.data; // Extract profile information

        // Extract email from profile
        const email = profile?.email;
        if (!email) {
            return res.status(400).json({ message: 'Email not found in user profile.' });
        }

        // Update the database with the new tokens
        const updated = await authModel.updateOAuthToken(email, newAccessToken, refresh_token);
        if (!updated) {
            return res.status(404).json({ message: 'User not found to update token.' });
        }

        // Send response with the tokens and user profile
        res.json({
            message: 'Authentication successful!',
            refreshToken: refresh_token,
            accessToken: newAccessToken,
            profile,
        });
    } catch (error) {
        console.error('Error refreshing access token:', error.message);
        res.status(500).json({ message: 'Failed to refresh access token.', error: error.message });
    }
};


// Generate a random 6-digit OTP
const generateOtp = () => Math.floor(100000 + Math.random() * 900000).toString();

exports.sendOtp = async (req, res) => {
    const { email } = req.body;
    if (!email) return res.status(400).json({ status: "0", message: 'Email is required.' });

    try {
        // Fetch the user by email
        const users = await authModel.getUserByEmail(email);
        if (users.length === 0) {
            return res.status(200).json({ status: "0", message: 'User not found.' });
        }

        const otp = generateOtp(); // Generate OTP
        const userId = users[0].id;

        try{
        // Update OTP in the database
            await authModel.updateOtpByUserId(userId, otp);
        }catch(error){
            res.status(400).json({ status: "0", message: 'Failed to Generate otp' });
        }

        // Define email template
        const emailTemplate = `
            <html>
                <body>
                    <h1>Your OTP Code</h1>
                    <p>Dear User,</p>
                    <p>Your OTP code for Bharat Battle is: <strong>${otp}</strong></p>
                    <p>Please use this code to reset your account password.</p>
                    <p>Thank you!</p>
                    <hr>
                    <p style="font-size: 0.9em; color: gray;">
                        This is an autogenerated email. Please do not reply to this email.
                    </p>
                </body>
            </html>
        `;

        // Send email
        await sendEmail(email, 'Your OTP Code for Bharat Battle', emailTemplate);

        res.status(200).json({ status: "1", message: 'OTP sent successfully.' });
    } catch (error) {
        console.error('Error sending OTP:', error);
        res.status(500).json({ status: "0", message: 'Internal server error.' });
    }
};

exports.verifyOtp = async (req, res) => {
    const { email, otp } = req.body;
    if (!email || !otp) return res.status(400).json({ status: "0", message: 'Email and OTP are required.' });

    try {
        // Fetch the user by email
        const users = await authModel.getUserByEmail(email);
        if (users.length === 0) {
            return res.status(200).json({ status: "0", message: 'User not found.' });
        }

        const user = users[0];
        if (user.totp !== otp) {
            return res.status(200).json({ status: "0", message: 'Invalid OTP.' });
        }

        // Update `is_otp_validated` to 1
        await authModel.validateOtpByUserId(user.id);

        res.status(200).json({ status: "1", message: 'OTP validated successfully.' });
    } catch (error) {
        res.status(500).json({ status: "0", message: 'Internal server error.' });
    }
};